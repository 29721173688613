import { apiInstance } from 'common/api/base';
import { IReview } from 'features/review-item';

export interface IReviewResponse {
    count: number;
    cursors: {
        prev: string;
        next: string;
    };
    reviews: IReview[];
}

export const getReviewsDriver = async (driverId: number, cursor?: string): Promise<IReviewResponse> => {
    const response = await apiInstance.get(`/api/v2/reviews/driver/${driverId}`, {
        params: {
            cursor,
        },
    });
    return response.data;
};
