import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityId: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                preserveAspectRatio='xMinYMid meet'
                id='_Слой_2'
                data-name='Слой 2'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 542.95 138.35'>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
                <g id='Layer_1' data-name='Layer 1'>
                    <g>
                        <g>
                            <path
                                className='cls-1'
                                d='M0,69.17c0-19.21,0-28.81,2.68-36.54C7.55,18.59,18.59,7.55,32.64,2.68,40.37,0,49.97,0,69.17,0s28.81,0,36.54,2.68c14.05,4.87,25.09,15.91,29.96,29.96,2.68,7.73,2.68,17.33,2.68,36.54s0,28.81-2.68,36.54c-4.87,14.05-15.91,25.09-29.96,29.96-7.73,2.68-17.33,2.68-36.54,2.68s-28.81,0-36.54-2.68c-14.05-4.87-25.09-15.91-29.96-29.96-2.68-7.73-2.68-17.33-2.68-36.54'
                            />
                            <rect x='35.68' y='59.59' width='16.29' height='46.18' rx='2.62' ry='2.62' />
                            <rect x='35.68' y='32.53' width='16.29' height='15.85' rx='2.62' ry='2.62' />
                            <path
                                className='cls-2'
                                d='M75.89,89.95h-8.81c-1.57,0-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.61,2.62,2.62l8.81,.06c50.22,0,50.22-73.29,0-73.29l-8.81,.06c-1.57,.01-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.62,2.62,2.62h8.81c28.41,0,28.41,41.55,0,41.55'
                            />
                        </g>
                        <g>
                            <path
                                className='cls-2'
                                d='M327.3,18.51c8.47,0,14.15,6.65,12.55,14.82h-19.16c.42,3.46,3.29,5.56,6.5,5.55,2.93,0,5.35-1.33,6.65-4.02l5.44,2.78c-2.18,4.27-6.77,7.08-11.98,7.08-7.83-.04-13.27-5.87-13.27-13.11s5.49-13.06,13.27-13.11m6.39,10.43c-.63-2.65-3.5-4.6-6.41-4.6s-5.69,1.86-6.41,4.6h12.82Z'
                            />
                            <path d='M204.55,21.78l-.19-2.56h-5.98v24.79h6.44v-12.75c0-7.6,10.5-8.18,10.5-1.67v14.42h6.44v-15c0-11.11-12.25-12.47-17.23-7.23' />
                            <path d='M238.3,37.88h-4.96V18.59h4.96c5.68,0,9.69,3.97,9.69,9.65s-4.01,9.65-9.69,9.65h0Zm0-25.42h-11.4v31.55h11.4c9.19,0,16.13-6.76,16.13-15.78s-6.94-15.78-16.13-15.78h0Z' />
                            <path d='M264.97,21.91l-.19-2.69h-5.98v24.79h6.44v-10.77c0-6.08,4.63-8.7,9.74-7.57v-6.62c-3.11-.78-7.69-.3-10.02,2.87' />
                            <polygon points='307.27 19.22 301.18 37.02 295.13 19.22 288.28 19.22 297.34 44.01 305.05 44.01 314.12 19.22 307.27 19.22' />
                            <g>
                                <rect x='186.95' y='19.22' width='6.45' height='24.79' />
                                <rect x='186.95' y='9.62' width='6.45' height='6.45' />
                            </g>
                            <g>
                                <rect x='278.5' y='19.22' width='6.44' height='24.79' />
                                <rect x='278.5' y='9.62' width='6.45' height='6.45' />
                            </g>
                        </g>
                        <g>
                            <path d='M213.66,120.33c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.44-15.14,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.41c.17-8.77,4.99-14.88,18.58-14.88s18.06,6.02,18.06,16.68v26.75h-10.41Zm-.77-20.73c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z' />
                            <path d='M256.23,120.33v-25.63c0-6.54-1.89-8.34-7.22-8.34-5.85,0-8.69,3.27-8.69,9.72v24.25h-11.18v-42.31h10.66v6.19c2.58-4.73,6.88-7.31,13.67-7.31,8.08,0,14.02,4.9,14.02,14.1v29.33h-11.27Z' />
                            <path d='M296.56,92.46h10.41v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.29-7.91-17.29-17.03v-37.84h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.26v-11.01Z' />
                            <path d='M338.61,120.33c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.44-15.14,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.41c.17-8.77,4.99-14.88,18.58-14.88s18.06,6.02,18.06,16.68v26.75h-10.41Zm-.77-20.73c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z' />
                            <path d='M354.01,120.33v-42.31h10.49v5.07c3.7-5.68,8-6.19,12.56-6.19h1.46v11.44c-1.03-.17-2.06-.26-3.1-.26-6.88,0-10.23,3.44-10.23,10.23v22.02h-11.18Z' />
                            <path d='M408.44,120.33l-9.29-19.01-5.42,5.59v13.42h-11.27V62.54h11.27v31.56l13.93-16.08h13.67l-14.19,15.57,13.85,26.75h-12.56Z' />
                            <path d='M418.59,99.17c0-13.42,8.34-22.27,21.76-22.27s21.5,8.77,21.5,22.27-8.34,22.27-21.5,22.27-21.76-9.12-21.76-22.27Zm31.99,0c0-8.6-3.44-12.81-10.23-12.81s-10.23,4.21-10.23,12.81,3.44,12.9,10.23,12.9,10.23-4.3,10.23-12.9Z' />
                            <path d='M490.49,92.46h10.41v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.29-7.91-17.29-17.03v-37.84h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.26v-11.01Z' />
                            <path d='M532.54,120.33c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.44-15.14,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.41c.17-8.77,4.99-14.88,18.58-14.88s18.06,6.02,18.06,16.68v26.75h-10.41Zm-.77-20.73c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z' />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

    return (
        <svg
            preserveAspectRatio='xMinYMid meet'
            id='_Слой_2'
            data-name='Слой 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 542.95 138.35'>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
            <g id='Layer_1' data-name='Layer 1'>
                <g>
                    <g>
                        <path
                            className='cls-4'
                            d='M0,69.17c0-19.21,0-28.81,2.68-36.54C7.55,18.59,18.59,7.55,32.64,2.68,40.37,0,49.97,0,69.17,0s28.81,0,36.54,2.68c14.05,4.87,25.09,15.91,29.96,29.96,2.68,7.73,2.68,17.33,2.68,36.54s0,28.81-2.68,36.54c-4.87,14.05-15.91,25.09-29.96,29.96-7.73,2.68-17.33,2.68-36.54,2.68s-28.81,0-36.54-2.68c-14.05-4.87-25.09-15.91-29.96-29.96-2.68-7.73-2.68-17.33-2.68-36.54'
                        />
                        <rect x='35.68' y='59.59' width='16.29' height='46.18' rx='2.62' ry='2.62' />
                        <rect x='35.68' y='32.53' width='16.29' height='15.85' rx='2.62' ry='2.62' />
                        <path
                            className='cls-3'
                            d='M75.89,89.95h-8.81c-1.57,0-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.61,2.62,2.62l8.81,.06c50.22,0,50.22-73.29,0-73.29l-8.81,.06c-1.57,.01-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.62,2.62,2.62h8.81c28.41,0,28.41,41.55,0,41.55'
                        />
                    </g>
                    <g>
                        <path
                            className='cls-2'
                            d='M327.3,18.51c8.47,0,14.15,6.65,12.55,14.82h-19.16c.42,3.46,3.29,5.56,6.5,5.55,2.93,0,5.35-1.33,6.65-4.02l5.44,2.78c-2.18,4.27-6.77,7.08-11.98,7.08-7.83-.04-13.27-5.87-13.27-13.11s5.49-13.06,13.27-13.11m6.39,10.43c-.63-2.65-3.5-4.6-6.41-4.6s-5.69,1.86-6.41,4.6h12.82Z'
                        />
                        <path
                            className='cls-1'
                            d='M204.55,21.78l-.19-2.56h-5.98v24.79h6.44v-12.75c0-7.6,10.5-8.18,10.5-1.67v14.42h6.44v-15c0-11.11-12.25-12.47-17.23-7.23'
                        />
                        <path
                            className='cls-1'
                            d='M238.3,37.88h-4.96V18.59h4.96c5.68,0,9.69,3.97,9.69,9.65s-4.01,9.65-9.69,9.65h0Zm0-25.42h-11.4v31.55h11.4c9.19,0,16.13-6.76,16.13-15.78s-6.94-15.78-16.13-15.78h0Z'
                        />
                        <path
                            className='cls-1'
                            d='M264.97,21.91l-.19-2.69h-5.98v24.79h6.44v-10.77c0-6.08,4.63-8.7,9.74-7.57v-6.62c-3.11-.78-7.69-.3-10.02,2.87'
                        />
                        <polygon
                            className='cls-1'
                            points='307.27 19.22 301.18 37.02 295.13 19.22 288.28 19.22 297.34 44.01 305.05 44.01 314.12 19.22 307.27 19.22'
                        />
                        <g>
                            <rect className='cls-1' x='186.95' y='19.22' width='6.45' height='24.79' />
                            <rect className='cls-1' x='186.95' y='9.62' width='6.45' height='6.45' />
                        </g>
                        <g>
                            <rect className='cls-1' x='278.5' y='19.22' width='6.44' height='24.79' />
                            <rect className='cls-1' x='278.5' y='9.62' width='6.45' height='6.45' />
                        </g>
                    </g>
                    <g>
                        <path
                            className='cls-1'
                            d='M213.66,120.33c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.44-15.14,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.41c.17-8.77,4.99-14.88,18.58-14.88s18.06,6.02,18.06,16.68v26.75h-10.41Zm-.77-20.73c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z'
                        />
                        <path
                            className='cls-1'
                            d='M256.23,120.33v-25.63c0-6.54-1.89-8.34-7.22-8.34-5.85,0-8.69,3.27-8.69,9.72v24.25h-11.18v-42.31h10.66v6.19c2.58-4.73,6.88-7.31,13.67-7.31,8.08,0,14.02,4.9,14.02,14.1v29.33h-11.27Z'
                        />
                        <path
                            className='cls-1'
                            d='M296.56,92.46h10.41v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.29-7.91-17.29-17.03v-37.84h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.26v-11.01Z'
                        />
                        <path
                            className='cls-1'
                            d='M338.61,120.33c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.44-15.14,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.41c.17-8.77,4.99-14.88,18.58-14.88s18.06,6.02,18.06,16.68v26.75h-10.41Zm-.77-20.73c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z'
                        />
                        <path
                            className='cls-1'
                            d='M354.01,120.33v-42.31h10.49v5.07c3.7-5.68,8-6.19,12.56-6.19h1.46v11.44c-1.03-.17-2.06-.26-3.1-.26-6.88,0-10.23,3.44-10.23,10.23v22.02h-11.18Z'
                        />
                        <path
                            className='cls-1'
                            d='M408.44,120.33l-9.29-19.01-5.42,5.59v13.42h-11.27V62.54h11.27v31.56l13.93-16.08h13.67l-14.19,15.57,13.85,26.75h-12.56Z'
                        />
                        <path
                            className='cls-1'
                            d='M418.59,99.17c0-13.42,8.34-22.27,21.76-22.27s21.5,8.77,21.5,22.27-8.34,22.27-21.5,22.27-21.76-9.12-21.76-22.27Zm31.99,0c0-8.6-3.44-12.81-10.23-12.81s-10.23,4.21-10.23,12.81,3.44,12.9,10.23,12.9,10.23-4.3,10.23-12.9Z'
                        />
                        <path
                            className='cls-1'
                            d='M490.49,92.46h10.41v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.29-7.91-17.29-17.03v-37.84h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.26v-11.01Z'
                        />
                        <path
                            className='cls-1'
                            d='M532.54,120.33c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.44-15.14,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.41c.17-8.77,4.99-14.88,18.58-14.88s18.06,6.02,18.06,16.68v26.75h-10.41Zm-.77-20.73c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z'
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default LogoIntercityId;
