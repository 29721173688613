import { FC } from 'react';
import { ILogoProps } from 'shared/ui';

const LogoIntercityEnOutstation: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                id='_Слой_2'
                xmlns='http://www.w3.org/2000/svg'
                preserveAspectRatio='xMinYMid meet'
                viewBox='0 0 576 138.33'>
                <defs>
                    <style jsx global>{`
                        .cls-1 {
                            fill: #a7e92f;
                        }

                        .cls-2 {
                            fill-rule: evenodd;
                        }
                    `}</style>
                </defs>
                <path
                    className='cls-1'
                    d='m0,69.17c0-19.2,0-28.81,2.68-36.53C7.55,18.59,18.59,7.55,32.63,2.68,40.36,0,49.96,0,69.17,0S97.97,0,105.7,2.68c14.05,4.87,25.09,15.91,29.96,29.96,2.68,7.73,2.68,17.33,2.68,36.53s0,28.81-2.68,36.53c-4.87,14.05-15.91,25.09-29.96,29.96-7.73,2.68-17.33,2.68-36.53,2.68s-28.81,0-36.53-2.68c-14.05-4.87-25.09-15.91-29.96-29.96-2.68-7.73-2.68-17.33-2.68-36.53'
                />
                <rect x='35.67' y='59.58' width='16.28' height='46.17' rx='2.62' ry='2.62' />
                <rect x='35.67' y='32.52' width='16.28' height='15.84' rx='2.62' ry='2.62' />
                <path
                    className='cls-2'
                    d='m75.88,89.94h-8.81c-1.57,0-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.61,2.62,2.62l8.81.06c50.22,0,50.22-73.28,0-73.28l-8.81.06c-1.57.01-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.62,2.62,2.62h8.81c28.41,0,28.41,41.54,0,41.54'
                />
                <path
                    className='cls-2'
                    d='m327.26,18.51c8.47,0,14.15,6.65,12.54,14.82h-19.16c.42,3.46,3.28,5.55,6.5,5.55,2.93,0,5.35-1.33,6.65-4.02l5.44,2.78c-2.18,4.27-6.77,7.08-11.97,7.08-7.83-.04-13.27-5.87-13.27-13.1s5.49-13.06,13.27-13.1m6.39,10.43c-.63-2.65-3.5-4.6-6.41-4.6s-5.69,1.86-6.41,4.6h12.82Z'
                />
                <path d='m204.52,21.78l-.19-2.56h-5.98v24.79h6.44v-12.75c0-7.6,10.5-8.18,10.5-1.67v14.42h6.44v-15c0-11.11-12.25-12.46-17.23-7.22' />
                <path d='m238.27,37.87h-4.96v-19.29h4.96c5.68,0,9.69,3.97,9.69,9.64s-4.01,9.64-9.69,9.64h0Zm0-25.41h-11.4v31.54h11.4c9.19,0,16.13-6.76,16.13-15.77s-6.94-15.77-16.13-15.77h0Z' />
                <path d='m264.93,21.91l-.19-2.69h-5.98v24.78h6.44v-10.77c0-6.08,4.63-8.69,9.74-7.57v-6.62c-3.11-.78-7.69-.3-10.02,2.87' />
                <polygon points='307.23 19.22 301.14 37.02 295.09 19.22 288.24 19.22 297.31 44 305.01 44 314.08 19.22 307.23 19.22' />
                <rect x='186.93' y='19.22' width='6.45' height='24.78' />
                <rect x='186.93' y='9.62' width='6.45' height='6.45' />
                <rect x='278.47' y='19.22' width='6.44' height='24.78' />
                <rect x='278.47' y='9.62' width='6.45' height='6.45' />
                <path d='m185.95,97.16c0-13.41,8.34-22.27,21.76-22.27s21.5,8.77,21.5,22.27-8.34,22.27-21.5,22.27-21.76-9.12-21.76-22.27Zm31.99,0c0-8.6-3.44-12.81-10.23-12.81s-10.23,4.21-10.23,12.81,3.44,12.9,10.23,12.9,10.23-4.3,10.23-12.9Z' />
                <path d='m260.51,118.31v-5.93c-2.92,4.82-7.31,7.05-13.16,7.05-8.43,0-14.36-6.28-14.36-15.56v-27.86h11.18v26.14c0,5.33,2.41,7.91,7.14,7.91,5.68,0,8.68-4.04,8.68-9.63v-24.42h11.09v42.31h-10.58Z' />
                <path d='m300.15,90.45h10.4v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.28-7.91-17.28-17.03v-37.83h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.25v-11.01Z' />
                <path d='m340.56,88.82c-.6-4.13-2.84-5.59-7.91-5.59-4.21,0-6.62,1.03-6.62,3.53s2.32,3.53,6.88,4.82c4.81,1.38,9.37,2.32,12.64,3.61,4.47,1.81,6.96,4.73,6.96,10.23,0,8.77-6.45,14.02-18.49,14.02-12.98,0-20.21-6.11-20.38-14.7h11.52c0,3.96,3.35,6.28,8.77,6.28,3.96,0,7.48-1.2,7.48-4.39,0-3.01-3.18-3.96-6.79-4.82-7.14-1.72-11.09-2.92-14.1-4.82-3.96-2.49-5.33-5.76-5.33-9.54,0-7.14,4.9-12.55,17.8-12.55,12.21,0,17.71,4.82,18.32,13.93h-10.75Z' />
                <path d='m379.43,90.45h10.4v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.28-7.91-17.28-17.03v-37.83h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.25v-11.01Z' />
                <path d='m420.7,118.31c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.43-15.13,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.4c.17-8.77,4.99-14.88,18.57-14.88s18.06,6.02,18.06,16.68v26.74h-10.4Zm-.77-20.72c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z' />
                <path d='m460.17,90.45h10.4v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.28-7.91-17.28-17.03v-37.83h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.25v-11.01Z' />
                <path d='m475.65,70.93v-10.4h11.18v10.4h-11.18Zm0,47.38v-42.31h11.18v42.31h-11.18Z' />
                <path d='m490.61,97.16c0-13.41,8.34-22.27,21.76-22.27s21.5,8.77,21.5,22.27-8.34,22.27-21.5,22.27-21.76-9.12-21.76-22.27Zm31.99,0c0-8.6-3.44-12.81-10.23-12.81s-10.23,4.21-10.23,12.81,3.44,12.9,10.23,12.9,10.23-4.3,10.23-12.9Z' />
                <path d='m564.74,118.31v-25.62c0-6.54-1.89-8.34-7.22-8.34-5.85,0-8.68,3.27-8.68,9.72v24.25h-11.18v-42.31h10.66v6.19c2.58-4.73,6.88-7.31,13.67-7.31,8.08,0,14.02,4.9,14.02,14.1v29.32h-11.27Z' />
            </svg>
        );
    }

    return (
        <svg id='_Слой_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 138.33'>
            <defs>
                <style jsx global>{`
                    .cls-1,
                    .cls-2 {
                        fill: #fff;
                    }

                    .cls-2,
                    .cls-3 {
                        fill-rule: evenodd;
                    }

                    .cls-4 {
                        fill: #a7e92f;
                    }
                `}</style>
            </defs>
            <path
                className='cls-4'
                d='m0,69.17c0-19.2,0-28.81,2.68-36.53C7.55,18.59,18.59,7.55,32.63,2.68,40.36,0,49.96,0,69.17,0S97.97,0,105.7,2.68c14.05,4.87,25.09,15.91,29.96,29.96,2.68,7.73,2.68,17.33,2.68,36.53s0,28.81-2.68,36.53c-4.87,14.05-15.91,25.09-29.96,29.96-7.73,2.68-17.33,2.68-36.53,2.68s-28.81,0-36.53-2.68c-14.05-4.87-25.09-15.91-29.96-29.96-2.68-7.73-2.68-17.33-2.68-36.53'
            />
            <rect x='35.67' y='59.58' width='16.28' height='46.17' rx='2.62' ry='2.62' />
            <rect x='35.67' y='32.52' width='16.28' height='15.84' rx='2.62' ry='2.62' />
            <path
                className='cls-3'
                d='m75.88,89.94h-8.81c-1.57,0-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.61,2.62,2.62l8.81.06c50.22,0,50.22-73.28,0-73.28l-8.81.06c-1.57.01-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.62,2.62,2.62h8.81c28.41,0,28.41,41.54,0,41.54'
            />
            <path
                className='cls-2'
                d='m327.26,18.51c8.47,0,14.15,6.65,12.54,14.82h-19.16c.42,3.46,3.28,5.55,6.5,5.55,2.93,0,5.35-1.33,6.65-4.02l5.44,2.78c-2.18,4.27-6.77,7.08-11.97,7.08-7.83-.04-13.27-5.87-13.27-13.1s5.49-13.06,13.27-13.1m6.39,10.43c-.63-2.65-3.5-4.6-6.41-4.6s-5.69,1.86-6.41,4.6h12.82Z'
            />
            <path
                className='cls-1'
                d='m204.52,21.78l-.19-2.56h-5.98v24.79h6.44v-12.75c0-7.6,10.5-8.18,10.5-1.67v14.42h6.44v-15c0-11.11-12.25-12.46-17.23-7.22'
            />
            <path
                className='cls-1'
                d='m238.27,37.87h-4.96v-19.29h4.96c5.68,0,9.69,3.97,9.69,9.64s-4.01,9.64-9.69,9.64h0Zm0-25.41h-11.4v31.54h11.4c9.19,0,16.13-6.76,16.13-15.77s-6.94-15.77-16.13-15.77h0Z'
            />
            <path
                className='cls-1'
                d='m264.93,21.91l-.19-2.69h-5.98v24.78h6.44v-10.77c0-6.08,4.63-8.69,9.74-7.57v-6.62c-3.11-.78-7.69-.3-10.02,2.87'
            />
            <polygon
                className='cls-1'
                points='307.23 19.22 301.14 37.02 295.09 19.22 288.24 19.22 297.31 44 305.01 44 314.08 19.22 307.23 19.22'
            />
            <rect className='cls-1' x='186.93' y='19.22' width='6.45' height='24.78' />
            <rect className='cls-1' x='186.93' y='9.62' width='6.45' height='6.45' />
            <rect className='cls-1' x='278.47' y='19.22' width='6.44' height='24.78' />
            <rect className='cls-1' x='278.47' y='9.62' width='6.45' height='6.45' />
            <path
                className='cls-1'
                d='m185.95,97.16c0-13.41,8.34-22.27,21.76-22.27s21.5,8.77,21.5,22.27-8.34,22.27-21.5,22.27-21.76-9.12-21.76-22.27Zm31.99,0c0-8.6-3.44-12.81-10.23-12.81s-10.23,4.21-10.23,12.81,3.44,12.9,10.23,12.9,10.23-4.3,10.23-12.9Z'
            />
            <path
                className='cls-1'
                d='m260.51,118.31v-5.93c-2.92,4.82-7.31,7.05-13.16,7.05-8.43,0-14.36-6.28-14.36-15.56v-27.86h11.18v26.14c0,5.33,2.41,7.91,7.14,7.91,5.68,0,8.68-4.04,8.68-9.63v-24.42h11.09v42.31h-10.58Z'
            />
            <path
                className='cls-1'
                d='m300.15,90.45h10.4v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.28-7.91-17.28-17.03v-37.83h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.25v-11.01Z'
            />
            <path
                className='cls-1'
                d='m340.56,88.82c-.6-4.13-2.84-5.59-7.91-5.59-4.21,0-6.62,1.03-6.62,3.53s2.32,3.53,6.88,4.82c4.81,1.38,9.37,2.32,12.64,3.61,4.47,1.81,6.96,4.73,6.96,10.23,0,8.77-6.45,14.02-18.49,14.02-12.98,0-20.21-6.11-20.38-14.7h11.52c0,3.96,3.35,6.28,8.77,6.28,3.96,0,7.48-1.2,7.48-4.39,0-3.01-3.18-3.96-6.79-4.82-7.14-1.72-11.09-2.92-14.1-4.82-3.96-2.49-5.33-5.76-5.33-9.54,0-7.14,4.9-12.55,17.8-12.55,12.21,0,17.71,4.82,18.32,13.93h-10.75Z'
            />
            <path
                className='cls-1'
                d='m379.43,90.45h10.4v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.28-7.91-17.28-17.03v-37.83h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.25v-11.01Z'
            />
            <path
                className='cls-1'
                d='m420.7,118.31c-.34-1.03-.6-2.49-.69-3.78-2.67,2.92-7.14,4.9-12.81,4.9-9.63,0-14.1-4.73-14.1-11.61,0-12.21,8.17-13.59,19.43-15.13,5.59-.77,7.14-1.89,7.14-4.82,0-2.75-2.75-4.39-7.14-4.39-5.07,0-7.14,2.49-7.65,6.28h-10.4c.17-8.77,4.99-14.88,18.57-14.88s18.06,6.02,18.06,16.68v26.74h-10.4Zm-.77-20.72c-1.12,1.12-3.1,1.72-7.22,2.49-6.36,1.2-8.43,3.01-8.43,6.62,0,3.18,1.89,4.73,5.42,4.73,5.76,0,10.06-4.21,10.15-9.2l.09-4.64Z'
            />
            <path
                className='cls-1'
                d='m460.17,90.45h10.4v12.04c0,8.94-5.16,16.94-17.11,16.94s-17.28-7.91-17.28-17.03v-37.83h11.01v11.44h23.39v7.91h-23.39v17.37c0,5.33,1.89,8.43,6.45,8.43s6.54-3.01,6.54-8.25v-11.01Z'
            />
            <path className='cls-1' d='m475.65,70.93v-10.4h11.18v10.4h-11.18Zm0,47.38v-42.31h11.18v42.31h-11.18Z' />
            <path
                className='cls-1'
                d='m490.61,97.16c0-13.41,8.34-22.27,21.76-22.27s21.5,8.77,21.5,22.27-8.34,22.27-21.5,22.27-21.76-9.12-21.76-22.27Zm31.99,0c0-8.6-3.44-12.81-10.23-12.81s-10.23,4.21-10.23,12.81,3.44,12.9,10.23,12.9,10.23-4.3,10.23-12.9Z'
            />
            <path
                className='cls-1'
                d='m564.74,118.31v-25.62c0-6.54-1.89-8.34-7.22-8.34-5.85,0-8.68,3.27-8.68,9.72v24.25h-11.18v-42.31h10.66v6.19c2.58-4.73,6.88-7.31,13.67-7.31,8.08,0,14.02,4.9,14.02,14.1v29.32h-11.27Z'
            />
        </svg>
    );
};

export default LogoIntercityEnOutstation;
