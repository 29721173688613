import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityEn: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                preserveAspectRatio='xMinYMid meet'
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 590 138.5'>
                <g>
                    <path
                        className='cls-1'
                        d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.2,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                    />
                    <rect x='35.7' y='59.6' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                    <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                    <path
                        className='cls-2'
                        d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                    />
                </g>
                <g>
                    <path
                        className='cls-2'
                        d='m327.6,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                    />
                    <path d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2' />
                    <path d='m238.5,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z' />
                    <path d='m265.2,21.9l-.2-2.7h-6v24.8h6.4v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9' />
                    <polygon points='307.6 19.2 301.5 37.1 295.4 19.2 288.6 19.2 297.6 44.1 305.4 44.1 314.4 19.2 307.6 19.2' />
                    <g>
                        <rect x='187.1' y='19.2' width='6.5' height='24.8' />
                        <rect x='187.1' y='9.6' width='6.5' height='6.5' />
                    </g>
                    <g>
                        <rect x='278.8' y='19.2' width='6.4' height='24.8' />
                        <rect x='278.8' y='9.6' width='6.5' height='6.5' />
                    </g>
                </g>
                <g>
                    <path d='m225.2,103.1c-1.2,9.8-9.2,16.4-19.5,16.4s-19.5-7.7-19.5-22.8,7.9-21.8,19.8-21.8,18.5,6.3,19.2,16.3h-11.3c-.6-4.4-3.8-7-8-7s-8.6,3-8.6,12.2,3.9,13.7,8.3,13.7,7.8-2.5,8.3-7.1h11.3Z' />
                    <path d='m229,71v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                    <path d='m269.3,90.6h10.4v12.1c0,9-5.2,17-17.1,17s-17.3-7.9-17.3-17v-37.9h11v11.4h23.4v7.9h-23.4v17.4c0,5.3,1.9,8.4,6.5,8.4s6.5-3,6.5-8.3v-11Z' />
                    <path d='m311.2,112.8c-1.5,3.8-6.2,6-11.4,6-8.9,0-15.5-6-15.5-15.3v-27.5h11.2v25.8c0,5.3,2.4,7.9,7.1,7.9s8.6-3.4,8.6-7.9v-25.8h11.2v43.5c0,12-8.6,18.9-19.5,18.9s-18.7-6.8-18.7-17.7h10.4c0,5,2.9,8.1,8.8,8.1s7.7-3.5,7.7-9.1v-6.9Z' />
                    <path d='m372.3,90.6h10.4v12.1c0,9-5.2,17-17.1,17s-17.3-7.9-17.3-17v-37.9h11v11.4h23.4v7.9h-23.4v17.4c0,5.3,1.9,8.4,6.5,8.4s6.5-3,6.5-8.3v-11Z' />
                    <path d='m386.6,97.3c0-13.4,8.4-22.3,21.8-22.3s21.5,8.8,21.5,22.3-8.4,22.3-21.5,22.3-21.8-9.1-21.8-22.3Zm32,0c0-8.6-3.4-12.8-10.2-12.8s-10.2,4.2-10.2,12.8,3.4,12.9,10.2,12.9,10.2-4.3,10.2-12.9Z' />
                    <path d='m492.8,103.1c-1.2,9.8-9.2,16.4-19.5,16.4s-19.5-7.7-19.5-22.8,7.9-21.8,19.8-21.8,18.5,6.3,19.2,16.3h-11.3c-.6-4.4-3.8-7-8-7s-8.6,3-8.6,12.2,3.9,13.7,8.3,13.7,7.8-2.5,8.3-7.1h11.3Z' />
                    <path d='m496.6,71v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                    <path d='m536.9,90.6h10.4v12.1c0,9-5.2,17-17.1,17s-17.3-7.9-17.3-17v-37.9h11v11.4h23.4v7.9h-23.4v17.4c0,5.3,1.9,8.4,6.5,8.4s6.5-3,6.5-8.3v-11Z' />
                    <path d='m578.8,112.8c-1.5,3.8-6.2,6-11.4,6-8.9,0-15.5-6-15.5-15.3v-27.5h11.2v25.8c0,5.3,2.4,7.9,7.1,7.9s8.6-3.4,8.6-7.9v-25.8h11.2v43.5c0,12-8.6,18.9-19.5,18.9s-18.7-6.8-18.7-17.7h10.4c0,5,2.9,8.1,8.8,8.1s7.7-3.5,7.7-9.1v-6.9Z' />
                </g>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
            </svg>
        );
    }

    return (
        <svg
            preserveAspectRatio='xMinYMid meet'
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 590 138.5'>
            <g>
                <path
                    className='cls-4'
                    d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.2,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                />
                <rect x='35.7' y='59.6' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                <path
                    className='cls-3'
                    d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                />
            </g>
            <g>
                <path
                    className='cls-2'
                    d='m327.6,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                />
                <path
                    className='cls-1'
                    d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2'
                />
                <path
                    className='cls-1'
                    d='m238.5,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z'
                />
                <path
                    className='cls-1'
                    d='m265.2,21.9l-.2-2.7h-6v24.8h6.4v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9'
                />
                <polygon
                    className='cls-1'
                    points='307.6 19.2 301.5 37.1 295.4 19.2 288.6 19.2 297.6 44.1 305.4 44.1 314.4 19.2 307.6 19.2'
                />
                <g>
                    <rect className='cls-1' x='187.1' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='187.1' y='9.6' width='6.5' height='6.5' />
                </g>
                <g>
                    <rect className='cls-1' x='278.8' y='19.2' width='6.4' height='24.8' />
                    <rect className='cls-1' x='278.8' y='9.6' width='6.5' height='6.5' />
                </g>
            </g>
            <g>
                <path
                    className='cls-1'
                    d='m225.2,103.1c-1.2,9.8-9.2,16.4-19.5,16.4s-19.5-7.7-19.5-22.8,7.9-21.8,19.8-21.8,18.5,6.3,19.2,16.3h-11.3c-.6-4.4-3.8-7-8-7s-8.6,3-8.6,12.2,3.9,13.7,8.3,13.7,7.8-2.5,8.3-7.1h11.3Z'
                />
                <path className='cls-1' d='m229,71v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                <path
                    className='cls-1'
                    d='m269.3,90.6h10.4v12.1c0,9-5.2,17-17.1,17s-17.3-7.9-17.3-17v-37.9h11v11.4h23.4v7.9h-23.4v17.4c0,5.3,1.9,8.4,6.5,8.4s6.5-3,6.5-8.3v-11Z'
                />
                <path
                    className='cls-1'
                    d='m311.2,112.8c-1.5,3.8-6.2,6-11.4,6-8.9,0-15.5-6-15.5-15.3v-27.5h11.2v25.8c0,5.3,2.4,7.9,7.1,7.9s8.6-3.4,8.6-7.9v-25.8h11.2v43.5c0,12-8.6,18.9-19.5,18.9s-18.7-6.8-18.7-17.7h10.4c0,5,2.9,8.1,8.8,8.1s7.7-3.5,7.7-9.1v-6.9Z'
                />
                <path
                    className='cls-1'
                    d='m372.3,90.6h10.4v12.1c0,9-5.2,17-17.1,17s-17.3-7.9-17.3-17v-37.9h11v11.4h23.4v7.9h-23.4v17.4c0,5.3,1.9,8.4,6.5,8.4s6.5-3,6.5-8.3v-11Z'
                />
                <path
                    className='cls-1'
                    d='m386.6,97.3c0-13.4,8.4-22.3,21.8-22.3s21.5,8.8,21.5,22.3-8.4,22.3-21.5,22.3-21.8-9.1-21.8-22.3Zm32,0c0-8.6-3.4-12.8-10.2-12.8s-10.2,4.2-10.2,12.8,3.4,12.9,10.2,12.9,10.2-4.3,10.2-12.9Z'
                />
                <path
                    className='cls-1'
                    d='m492.8,103.1c-1.2,9.8-9.2,16.4-19.5,16.4s-19.5-7.7-19.5-22.8,7.9-21.8,19.8-21.8,18.5,6.3,19.2,16.3h-11.3c-.6-4.4-3.8-7-8-7s-8.6,3-8.6,12.2,3.9,13.7,8.3,13.7,7.8-2.5,8.3-7.1h11.3Z'
                />
                <path className='cls-1' d='m496.6,71v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                <path
                    className='cls-1'
                    d='m536.9,90.6h10.4v12.1c0,9-5.2,17-17.1,17s-17.3-7.9-17.3-17v-37.9h11v11.4h23.4v7.9h-23.4v17.4c0,5.3,1.9,8.4,6.5,8.4s6.5-3,6.5-8.3v-11Z'
                />
                <path
                    className='cls-1'
                    d='m578.8,112.8c-1.5,3.8-6.2,6-11.4,6-8.9,0-15.5-6-15.5-15.3v-27.5h11.2v25.8c0,5.3,2.4,7.9,7.1,7.9s8.6-3.4,8.6-7.9v-25.8h11.2v43.5c0,12-8.6,18.9-19.5,18.9s-18.7-6.8-18.7-17.7h10.4c0,5,2.9,8.1,8.8,8.1s7.7-3.5,7.7-9.1v-6.9Z'
                />
            </g>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
        </svg>
    );
};

export default LogoIntercityEn;
