import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityNe: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 566.2 138.5'>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
                <g>
                    <path
                        className='cls-1'
                        d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.3,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                    />
                    <rect x='35.7' y='59.7' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                    <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                    <path
                        className='cls-2'
                        d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                    />
                </g>
                <g>
                    <path
                        className='cls-2'
                        d='m327.7,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                    />
                    <path d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2' />
                    <path d='m238.6,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z' />
                    <path d='m265.3,21.9l-.2-2.7h-6v24.8h6.5v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9' />
                    <polygon points='307.6 19.2 301.5 37.1 295.5 19.2 288.6 19.2 297.7 44.1 305.4 44.1 314.5 19.2 307.6 19.2' />
                    <g>
                        <rect x='187.2' y='19.2' width='6.5' height='24.8' />
                        <rect x='187.2' y='9.6' width='6.5' height='6.5' />
                    </g>
                    <g>
                        <rect x='278.8' y='19.2' width='6.5' height='24.8' />
                        <rect x='278.8' y='9.6' width='6.5' height='6.5' />
                    </g>
                </g>
                <g>
                    <path d='m194,110.3c-1.8,0-3.4-.5-4.8-1.4-1.4-1-2.1-2.4-2.1-4.4s.5-2.9,1.5-3.9c1-1,2.4-1.5,4.3-1.5s2,.2,3.1.6c1.1.4,2.2,1,3.4,1.8,1.9-.9,3.4-2.2,4.6-3.9,1.2-1.7,1.8-3.9,1.8-6.5s-.5-4.2-1.6-5.4c-1.1-1.2-2.4-1.8-4-1.8s-1.7.2-2.2.7c-.6.4-.8,1.1-.8,1.9s.5,1.7,1.4,2.4c.9.7,2.7,1.3,5.4,1.6l-1.9,6.2c-4.6-.4-8-1.6-10.2-3.5-2.2-1.9-3.3-4.2-3.3-6.9s1-4.8,2.9-6.5c2-1.7,4.8-2.6,8.5-2.6s5.1.5,7.4,1.5c2.3,1,4.1,2.6,5.5,4.7,1.4,2.1,2.1,4.8,2.1,8.1s-.8,6.3-2.3,9-3.6,5-6.3,6.7c1.6,1.4,3.1,3,4.5,4.6,1.5,1.7,2.8,3.3,4.1,4.8l-6.8,4.7c-1.8-2.4-3.6-4.5-5.2-6.3-1.6-1.8-3.1-3.4-4.5-4.8-1.4.2-2.9.3-4.4.3Zm36.8-25v35.3h-9.2v-35.3h-5.2v-7.6h20.9v7.6h-6.5Z' />
                    <path d='m259.4,115.6c1.2-.3,2.3-.7,3.1-1.3.8-.5,1.2-1.3,1.2-2.2s-.5-2-1.6-2.5c-1.1-.6-2.8-.9-5.2-.9s-4.8.5-6.2,1.5c-1.4,1-2.1,2.3-2.1,4s.3,2.3.8,3.3c.5,1.1,1.5,2.3,2.9,3.6,1.4,1.3,3.4,3,6,4.9l-6,6.1c-2.6-2.2-4.9-4.2-6.8-6.1-1.9-1.9-3.4-3.9-4.5-5.9-1.1-2-1.6-4.2-1.6-6.5s.3-3.3,1-4.6c.7-1.4,1.6-2.5,2.7-3.5-.8-1-1.5-2.1-2-3.3-.5-1.2-.8-2.6-.8-4.2s.3-2.7.8-3.8c.5-1,1.2-1.9,2.1-2.5,1.1-.9,2.5-1.6,4.1-2,1.6-.4,3.8-.6,6.6-.6h7v-3.9h-24.5v-7.6h40.2v7.6h-6.5v11.4h-16c-1.3,0-2.2,0-2.7.2-.5.1-.9.3-1.3.6-.6.5-.9,1.1-.9,2.1s.3,1.6,1,2.3c2.2-.6,4.5-.8,7-.8,3.7,0,6.7.5,9,1.6,2.3,1,4,2.4,5,4,1.1,1.6,1.6,3.4,1.6,5.3,0,5.6-3.6,9.3-10.9,11l-2.5-7.3Z' />
                    <path d='m304.3,115.6l-6,6.1c-4-3.4-7.7-6.7-10.9-9.8-3.2-3.1-5.6-5.7-7.2-7.8-1-1.4-1.7-2.5-2-3.5-.3-1-.5-1.8-.5-2.6,0-1.2.4-2.4,1.2-3.5.8-1.1,2.2-1.7,4.2-1.7s4.2.8,5.9,2.5c2.4-1.6,3.7-3.7,3.7-6.4s-.1-2.5-.4-3.7h-16.7v-7.6h31.2v7.6h-5.5c.2.6.3,1.4.4,2.2.1.9.2,1.8.2,2.9,0,3.2-1,5.8-2.9,8-1.9,2.2-4.7,3.9-8.2,5.3,1.8,1.8,3.9,3.7,6.2,5.9,2.3,2.1,4.8,4.1,7.3,6Z' />
                    <path d='m335.1,125.3c-.9-1.5-1.8-3.1-2.7-4.8-.9-1.7-1.6-3.2-2.2-4.4-1.2.1-2.5.2-4.1.2-2.2,0-4.3-.2-6.4-.7-2.1-.5-4-1.3-5.8-2.3-1.7-1.1-3.1-2.5-4.2-4.4-1-1.8-1.6-4.1-1.6-6.8s.4-3.8,1.1-5.3c.8-1.5,1.8-2.7,3.1-3.8,1.5-1.1,3.2-2,5.3-2.7,2-.6,4.3-1,6.8-1.1v-3.9h-18.8v-7.6h39.4v7.6h-11.4v11.5h-3.5c-2.7,0-4.7.1-6.2.3-1.4.2-2.7.6-3.8,1.1-1.9.9-2.9,2.4-2.9,4.4s.7,3.2,2.1,4.3c1.4,1.1,3.9,1.7,7.4,1.7h.5c.2,0,.4,0,.7,0,0-.5-.1-1-.1-1.4,0-1.7.6-2.9,1.9-3.7,1.2-.7,2.6-1.1,4-1.1,2.4,0,4.3.7,5.6,2,1.3,1.3,1.9,2.8,1.9,4.6s-.4,2.8-1.1,3.7c-.7.8-1.6,1.5-2.7,2,1.3,2.3,3,4.7,5,7.4l-7.5,3.3Z' />
                    <path d='m325,78.2c-.8-2.7-1.5-4.9-2.3-6.6-.8-1.7-1.6-3-2.6-3.8-1-.8-2.3-1.2-3.8-1.2s-1.7,0-2.5.3c-.8.2-1.5.4-2.2.6l-2.5-7.2c1.1-.5,2.2-.8,3.4-1,1.2-.2,2.4-.3,3.8-.3s3.5.2,5.1.7c1.6.5,3,1.4,4.4,2.7,1.4,1.3,2.6,3.3,3.9,5.8,1.2,2.5,2.4,5.9,3.5,10.1h-8.2Z' />
                    <path d='m349.7,85.3h-5.5v-7.6h5.2c-.6-1.2-1.1-2.4-1.4-3.5-.3-1.1-.5-2.3-.5-3.5,0-3.8,1.7-6.7,5.2-8.7,3.5-2.1,8.4-3.1,14.9-3.1s12.2.6,17.5,1.7c5.3,1.1,10.2,2.7,14.5,4.6,4.3,1.9,8.2,4,11.5,6.3,3.4,2.3,6.2,4.5,8.6,6.8h-11.6c-3.3-2.3-7.1-4.3-11.3-6.1-4.2-1.8-8.7-3.1-13.4-4.1-4.7-1-9.4-1.5-14.1-1.5s-7.4.5-9.5,1.6-3.1,2.7-3.1,4.9.1,1.7.4,2.5c.3.8.6,1.6,1.1,2.3h7.2v7.6h-6.5v35.3h-9.2v-35.3Z' />
                    <path d='m420.6,85.3v35.3h-9.2v-2.8c-3.8,1.8-8.1,2.7-13.1,2.7s-7-.5-10-1.6c-3.1-1.1-5.8-2.4-8.2-4.1-2.4-1.7-4.6-3.4-6.4-5.2-1.8-1.8-3.3-3.5-4.5-5.1-1.1-1.5-1.8-2.7-2.1-3.8-.3-1-.5-1.9-.5-2.7,0-1.2.4-2.4,1.2-3.5.8-1.1,2.2-1.7,4.2-1.7s3.9.7,5.4,2.1c.5-.9.9-1.8,1.1-2.8.2-1,.3-2,.3-3.1s-.2-2.5-.5-3.7h-13.9v-7.6h62.7v7.6h-6.5Zm-16.9,16.7c1.6,0,3-.3,4.2-.8,1.2-.5,2.4-1.2,3.4-2.1v-13.8h-24c.2.6.3,1.4.4,2.2.1.9.2,1.8.2,2.9,0,3.4-.9,6.2-2.5,8.2-1.7,2-3.8,3.7-6.3,5,1.5,1.6,3.1,3,4.9,4.4,1.8,1.4,3.9,2.5,6.2,3.4,2.3.9,5,1.3,8,1.3s5.7-.3,7.7-.9c2-.6,3.8-1.3,5.3-2.1v-3.1c-1.1.6-2.4,1.2-3.9,1.6-1.4.4-3.2.6-5.2.6-3.5,0-6.3-.9-8.5-2.7-2.2-1.8-3.3-4.3-3.3-7.6s1.1-5.9,3.4-7.8c2.2-1.9,5.3-2.9,9.2-2.9s1.8,0,3,.1c1.2,0,2.3.2,3.2.4l-.7,6.7c-.6-.1-1.2-.3-1.9-.3-.7,0-1.5-.1-2.4-.1-3.4,0-5.1,1.2-5.1,3.7s.4,2.2,1.3,2.8c.9.6,2,.9,3.2.9Z' />
                    <path d='m453.4,110.3c-1.8,0-3.4-.5-4.8-1.4-1.4-1-2.1-2.4-2.1-4.4s.5-2.9,1.5-3.9c1-1,2.4-1.5,4.3-1.5s2,.2,3.1.6c1.1.4,2.2,1,3.4,1.8,1.9-.9,3.4-2.2,4.6-3.9,1.2-1.7,1.8-3.9,1.8-6.5s-.5-4.2-1.6-5.4c-1.1-1.2-2.4-1.8-4-1.8s-1.7.2-2.2.7c-.6.4-.8,1.1-.8,1.9s.5,1.7,1.4,2.4c.9.7,2.7,1.3,5.4,1.6l-1.9,6.2c-4.6-.4-8-1.6-10.2-3.5-2.2-1.9-3.3-4.2-3.3-6.9s1-4.8,2.9-6.5c2-1.7,4.8-2.6,8.5-2.6s5.1.5,7.4,1.5c2.3,1,4.1,2.6,5.5,4.7,1.4,2.1,2.1,4.8,2.1,8.1s-.8,6.3-2.3,9-3.6,5-6.3,6.7c1.6,1.4,3.1,3,4.5,4.6,1.5,1.7,2.8,3.3,4.1,4.8l-6.8,4.7c-1.8-2.4-3.6-4.5-5.2-6.3-1.6-1.8-3.1-3.4-4.5-4.8-1.4.2-2.9.3-4.4.3Zm36.8-25v35.3h-9.2v-35.3h-5.2v-7.6h20.9v7.6h-6.5Z' />
                    <path d='m518.8,115.6c1.2-.3,2.3-.7,3.1-1.3.8-.5,1.2-1.3,1.2-2.2s-.5-2-1.6-2.5c-1.1-.6-2.8-.9-5.2-.9s-4.8.5-6.2,1.5c-1.4,1-2.1,2.3-2.1,4s.3,2.3.8,3.3c.5,1.1,1.5,2.3,2.9,3.6,1.4,1.3,3.4,3,6,4.9l-6,6.1c-2.6-2.2-4.9-4.2-6.8-6.1-1.9-1.9-3.4-3.9-4.5-5.9-1.1-2-1.6-4.2-1.6-6.5s.3-3.3,1-4.6c.7-1.4,1.6-2.5,2.7-3.5-.8-1-1.5-2.1-2-3.3-.5-1.2-.8-2.6-.8-4.2s.3-2.7.8-3.8c.5-1,1.2-1.9,2.1-2.5,1.1-.9,2.5-1.6,4.1-2,1.6-.4,3.8-.6,6.6-.6h7v-3.9h-24.5v-7.6h40.2v7.6h-6.5v11.4h-16c-1.3,0-2.2,0-2.7.2-.5.1-.9.3-1.3.6-.6.5-.9,1.1-.9,2.1s.3,1.6,1,2.3c2.2-.6,4.5-.8,7-.8,3.7,0,6.7.5,9,1.6,2.3,1,4,2.4,5,4,1.1,1.6,1.6,3.4,1.6,5.3,0,5.6-3.6,9.3-10.9,11l-2.5-7.3Z' />
                    <path d='m563.7,115.6l-6,6.1c-4-3.4-7.7-6.7-10.9-9.8-3.2-3.1-5.6-5.7-7.2-7.8-1-1.4-1.7-2.5-2-3.5-.3-1-.5-1.8-.5-2.6,0-1.2.4-2.4,1.2-3.5.8-1.1,2.2-1.7,4.2-1.7s4.2.8,5.9,2.5c2.4-1.6,3.7-3.7,3.7-6.4s-.1-2.5-.4-3.7h-16.7v-7.6h31.2v7.6h-5.5c.2.6.3,1.4.4,2.2.1.9.2,1.8.2,2.9,0,3.2-1,5.8-2.9,8-1.9,2.2-4.7,3.9-8.2,5.3,1.8,1.8,3.9,3.7,6.2,5.9,2.3,2.1,4.8,4.1,7.3,6Z' />
                </g>
            </svg>
        );
    }

    return (
        <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 566.2 138.5'>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
            <g>
                <path
                    className='cls-4'
                    d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.3,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                />
                <rect x='35.7' y='59.7' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                <path
                    className='cls-3'
                    d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                />
            </g>
            <g>
                <path
                    className='cls-2'
                    d='m327.7,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                />
                <path
                    className='cls-1'
                    d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2'
                />
                <path
                    className='cls-1'
                    d='m238.6,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z'
                />
                <path
                    className='cls-1'
                    d='m265.3,21.9l-.2-2.7h-6v24.8h6.5v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9'
                />
                <polygon
                    className='cls-1'
                    points='307.6 19.2 301.5 37.1 295.5 19.2 288.6 19.2 297.7 44.1 305.4 44.1 314.5 19.2 307.6 19.2'
                />
                <g>
                    <rect className='cls-1' x='187.2' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='187.2' y='9.6' width='6.5' height='6.5' />
                </g>
                <g>
                    <rect className='cls-1' x='278.8' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='278.8' y='9.6' width='6.5' height='6.5' />
                </g>
            </g>
            <g>
                <path
                    className='cls-1'
                    d='m194,110.3c-1.8,0-3.4-.5-4.8-1.4-1.4-1-2.1-2.4-2.1-4.4s.5-2.9,1.5-3.9c1-1,2.4-1.5,4.3-1.5s2,.2,3.1.6c1.1.4,2.2,1,3.4,1.8,1.9-.9,3.4-2.2,4.6-3.9,1.2-1.7,1.8-3.9,1.8-6.5s-.5-4.2-1.6-5.4c-1.1-1.2-2.4-1.8-4-1.8s-1.7.2-2.2.7c-.6.4-.8,1.1-.8,1.9s.5,1.7,1.4,2.4c.9.7,2.7,1.3,5.4,1.6l-1.9,6.2c-4.6-.4-8-1.6-10.2-3.5-2.2-1.9-3.3-4.2-3.3-6.9s1-4.8,2.9-6.5c2-1.7,4.8-2.6,8.5-2.6s5.1.5,7.4,1.5c2.3,1,4.1,2.6,5.5,4.7,1.4,2.1,2.1,4.8,2.1,8.1s-.8,6.3-2.3,9-3.6,5-6.3,6.7c1.6,1.4,3.1,3,4.5,4.6,1.5,1.7,2.8,3.3,4.1,4.8l-6.8,4.7c-1.8-2.4-3.6-4.5-5.2-6.3-1.6-1.8-3.1-3.4-4.5-4.8-1.4.2-2.9.3-4.4.3Zm36.8-25v35.3h-9.2v-35.3h-5.2v-7.6h20.9v7.6h-6.5Z'
                />
                <path
                    className='cls-1'
                    d='m259.4,115.6c1.2-.3,2.3-.7,3.1-1.3.8-.5,1.2-1.3,1.2-2.2s-.5-2-1.6-2.5c-1.1-.6-2.8-.9-5.2-.9s-4.8.5-6.2,1.5c-1.4,1-2.1,2.3-2.1,4s.3,2.3.8,3.3c.5,1.1,1.5,2.3,2.9,3.6,1.4,1.3,3.4,3,6,4.9l-6,6.1c-2.6-2.2-4.9-4.2-6.8-6.1-1.9-1.9-3.4-3.9-4.5-5.9-1.1-2-1.6-4.2-1.6-6.5s.3-3.3,1-4.6c.7-1.4,1.5-2.5,2.7-3.5-.8-1-1.5-2.1-2-3.3-.5-1.2-.8-2.6-.8-4.2s.3-2.7.8-3.8c.5-1,1.2-1.9,2.1-2.5,1.1-.9,2.5-1.6,4.1-2,1.6-.4,3.8-.6,6.6-.6h7v-3.9h-24.5v-7.6h40.2v7.6h-6.5v11.4h-16c-1.3,0-2.2,0-2.7.2-.5.1-.9.3-1.3.6-.6.5-.9,1.1-.9,2.1s.3,1.6,1,2.3c2.2-.6,4.5-.8,7-.8,3.7,0,6.7.5,9,1.6,2.3,1,4,2.4,5,4,1.1,1.6,1.6,3.4,1.6,5.3,0,5.6-3.6,9.3-10.9,11l-2.5-7.3Z'
                />
                <path
                    className='cls-1'
                    d='m304.3,115.6l-6,6.1c-4-3.4-7.7-6.7-10.9-9.8-3.2-3.1-5.6-5.7-7.2-7.8-1-1.4-1.7-2.5-2-3.5-.3-1-.5-1.8-.5-2.6,0-1.2.4-2.4,1.2-3.5.8-1.1,2.2-1.7,4.2-1.7s4.2.8,5.9,2.5c2.4-1.6,3.7-3.7,3.7-6.4s-.1-2.5-.4-3.7h-16.7v-7.6h31.2v7.6h-5.5c.2.6.3,1.4.4,2.2.1.9.2,1.8.2,2.9,0,3.2-1,5.8-2.9,8-1.9,2.2-4.7,3.9-8.2,5.3,1.8,1.8,3.9,3.7,6.2,5.9,2.3,2.1,4.8,4.1,7.3,6Z'
                />
                <path
                    className='cls-1'
                    d='m335.1,125.3c-.9-1.5-1.8-3.1-2.7-4.8-.9-1.7-1.6-3.2-2.2-4.4-1.2.1-2.5.2-4.1.2-2.2,0-4.3-.2-6.4-.7-2.1-.5-4-1.3-5.8-2.3-1.7-1.1-3.1-2.5-4.2-4.4-1-1.8-1.6-4.1-1.6-6.8s.4-3.8,1.1-5.3c.8-1.5,1.8-2.7,3.1-3.8,1.5-1.1,3.2-2,5.3-2.7,2-.6,4.3-1,6.8-1.1v-3.9h-18.8v-7.6h39.4v7.6h-11.4v11.5h-3.5c-2.7,0-4.7.1-6.2.3-1.4.2-2.7.6-3.8,1.1-1.9.9-2.9,2.4-2.9,4.4s.7,3.2,2.1,4.3c1.4,1.1,3.9,1.7,7.4,1.7h.5c.2,0,.4,0,.7,0,0-.5-.1-1-.1-1.4,0-1.7.6-2.9,1.9-3.7,1.2-.7,2.6-1.1,4-1.1,2.4,0,4.3.7,5.6,2,1.3,1.3,1.9,2.8,1.9,4.6s-.4,2.8-1.1,3.7c-.7.8-1.6,1.5-2.7,2,1.3,2.3,3,4.7,5,7.4l-7.5,3.3Z'
                />
                <path
                    className='cls-1'
                    d='m325,78.2c-.8-2.7-1.5-4.9-2.3-6.6s-1.6-3-2.6-3.8c-1-.8-2.3-1.2-3.8-1.2s-1.7,0-2.5.3c-.8.2-1.5.4-2.2.6l-2.5-7.2c1.1-.5,2.2-.8,3.4-1,1.2-.2,2.4-.3,3.8-.3s3.5.2,5.1.7c1.6.5,3,1.4,4.4,2.7,1.4,1.3,2.6,3.3,3.9,5.8,1.2,2.5,2.4,5.9,3.5,10.1h-8.2Z'
                />
                <path
                    className='cls-1'
                    d='m349.7,85.3h-5.5v-7.6h5.2c-.6-1.2-1.1-2.4-1.4-3.5-.3-1.1-.5-2.3-.5-3.5,0-3.8,1.7-6.7,5.2-8.7,3.5-2.1,8.4-3.1,14.9-3.1s12.2.6,17.5,1.7c5.3,1.1,10.2,2.7,14.5,4.6,4.3,1.9,8.2,4,11.5,6.3,3.4,2.3,6.2,4.5,8.6,6.8h-11.6c-3.3-2.3-7.1-4.3-11.3-6.1-4.2-1.8-8.7-3.1-13.4-4.1-4.7-1-9.4-1.5-14.1-1.5s-7.4.5-9.5,1.6-3.1,2.7-3.1,4.9.1,1.7.4,2.5c.3.8.6,1.6,1.1,2.3h7.2v7.6h-6.5v35.3h-9.2v-35.3Z'
                />
                <path
                    className='cls-1'
                    d='m420.6,85.3v35.3h-9.2v-2.8c-3.8,1.8-8.1,2.7-13.1,2.7s-7-.5-10-1.6c-3.1-1.1-5.8-2.4-8.2-4.1-2.4-1.7-4.6-3.4-6.4-5.2-1.8-1.8-3.3-3.5-4.5-5.1-1.1-1.5-1.8-2.7-2.1-3.8s-.5-1.9-.5-2.7c0-1.2.4-2.4,1.2-3.5.8-1.1,2.2-1.7,4.2-1.7s3.9.7,5.4,2.1c.5-.9.9-1.8,1.1-2.8.2-1,.3-2,.3-3.1s-.2-2.5-.5-3.7h-13.9v-7.6h62.7v7.6h-6.5Zm-16.9,16.7c1.6,0,3-.3,4.2-.8,1.2-.5,2.4-1.2,3.4-2.1v-13.8h-24c.2.6.3,1.4.4,2.2.1.9.2,1.8.2,2.9,0,3.4-.9,6.2-2.5,8.2-1.7,2-3.8,3.7-6.3,5,1.5,1.6,3.1,3,4.9,4.4,1.8,1.4,3.9,2.5,6.2,3.4,2.3.9,5,1.3,8,1.3s5.7-.3,7.7-.9c2-.6,3.8-1.3,5.3-2.1v-3.1c-1.1.6-2.4,1.2-3.9,1.6-1.4.4-3.2.6-5.2.6-3.5,0-6.3-.9-8.5-2.7-2.2-1.8-3.3-4.3-3.3-7.6s1.1-5.9,3.4-7.8c2.2-1.9,5.3-2.9,9.2-2.9s1.8,0,3,.1,2.3.2,3.2.4l-.7,6.7c-.6-.1-1.2-.3-1.9-.3-.7,0-1.5-.1-2.4-.1-3.4,0-5.1,1.2-5.1,3.7s.4,2.2,1.3,2.8c.9.6,2,.9,3.2.9Z'
                />
                <path
                    className='cls-1'
                    d='m453.4,110.3c-1.8,0-3.4-.5-4.8-1.4-1.4-1-2.1-2.4-2.1-4.4s.5-2.9,1.5-3.9c1-1,2.4-1.5,4.3-1.5s2,.2,3.1.6c1.1.4,2.2,1,3.4,1.8,1.9-.9,3.4-2.2,4.6-3.9,1.2-1.7,1.8-3.9,1.8-6.5s-.5-4.2-1.6-5.4c-1.1-1.2-2.4-1.8-4-1.8s-1.7.2-2.2.7c-.6.4-.8,1.1-.8,1.9s.5,1.7,1.4,2.4c.9.7,2.7,1.3,5.4,1.6l-1.9,6.2c-4.6-.4-8-1.6-10.2-3.5-2.2-1.9-3.3-4.2-3.3-6.9s1-4.8,2.9-6.5c2-1.7,4.8-2.6,8.5-2.6s5.1.5,7.4,1.5c2.3,1,4.1,2.6,5.5,4.7,1.4,2.1,2.1,4.8,2.1,8.1s-.8,6.3-2.3,9-3.6,5-6.3,6.7c1.6,1.4,3.1,3,4.5,4.6,1.5,1.7,2.8,3.3,4.1,4.8l-6.8,4.7c-1.8-2.4-3.6-4.5-5.2-6.3-1.6-1.8-3.1-3.4-4.5-4.8-1.4.2-2.9.3-4.4.3Zm36.8-25v35.3h-9.2v-35.3h-5.2v-7.6h20.9v7.6h-6.5Z'
                />
                <path
                    className='cls-1'
                    d='m518.8,115.6c1.2-.3,2.3-.7,3.1-1.3.8-.5,1.2-1.3,1.2-2.2s-.5-2-1.6-2.5c-1.1-.6-2.8-.9-5.2-.9s-4.8.5-6.2,1.5c-1.4,1-2.1,2.3-2.1,4s.3,2.3.8,3.3c.5,1.1,1.5,2.3,2.9,3.6,1.4,1.3,3.4,3,6,4.9l-6,6.1c-2.6-2.2-4.9-4.2-6.8-6.1-1.9-1.9-3.4-3.9-4.5-5.9-1.1-2-1.6-4.2-1.6-6.5s.3-3.3,1-4.6c.7-1.4,1.5-2.5,2.7-3.5-.8-1-1.5-2.1-2-3.3-.5-1.2-.8-2.6-.8-4.2s.3-2.7.8-3.8c.5-1,1.2-1.9,2.1-2.5,1.1-.9,2.5-1.6,4.1-2,1.6-.4,3.8-.6,6.6-.6h7v-3.9h-24.5v-7.6h40.2v7.6h-6.5v11.4h-16c-1.3,0-2.2,0-2.7.2-.5.1-.9.3-1.3.6-.6.5-.9,1.1-.9,2.1s.3,1.6,1,2.3c2.2-.6,4.5-.8,7-.8,3.7,0,6.7.5,9,1.6,2.3,1,4,2.4,5,4,1.1,1.6,1.6,3.4,1.6,5.3,0,5.6-3.6,9.3-10.9,11l-2.5-7.3Z'
                />
                <path
                    className='cls-1'
                    d='m563.7,115.6l-6,6.1c-4-3.4-7.7-6.7-10.9-9.8-3.2-3.1-5.6-5.7-7.2-7.8-1-1.4-1.7-2.5-2-3.5-.3-1-.5-1.8-.5-2.6,0-1.2.4-2.4,1.2-3.5.8-1.1,2.2-1.7,4.2-1.7s4.2.8,5.9,2.5c2.4-1.6,3.7-3.7,3.7-6.4s-.1-2.5-.4-3.7h-16.7v-7.6h31.2v7.6h-5.5c.2.6.3,1.4.4,2.2.1.9.2,1.8.2,2.9,0,3.2-1,5.8-2.9,8-1.9,2.2-4.7,3.9-8.2,5.3,1.8,1.8,3.9,3.7,6.2,5.9,2.3,2.1,4.8,4.1,7.3,6Z'
                />
            </g>
        </svg>
    );
};

export default LogoIntercityNe;
