import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityUr: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                preserveAspectRatio='xMinYMid meet'
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 651.1 138.5'>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
                <g>
                    <path
                        className='cls-1'
                        d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.3,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                    />
                    <rect x='35.7' y='59.7' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                    <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                    <path
                        className='cls-2'
                        d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                    />
                </g>
                <g>
                    <path
                        className='cls-2'
                        d='m327.7,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                    />
                    <path d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2' />
                    <path d='m238.6,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z' />
                    <path d='m265.3,21.9l-.2-2.7h-6v24.8h6.5v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9' />
                    <polygon points='307.6 19.2 301.5 37.1 295.5 19.2 288.6 19.2 297.7 44.1 305.4 44.1 314.5 19.2 307.6 19.2' />
                    <g>
                        <rect x='187.2' y='19.2' width='6.5' height='24.8' />
                        <rect x='187.2' y='9.6' width='6.5' height='6.5' />
                    </g>
                    <g>
                        <rect x='278.8' y='19.2' width='6.5' height='24.8' />
                        <rect x='278.8' y='9.6' width='6.5' height='6.5' />
                    </g>
                </g>
                <g>
                    <path d='m203.7,112v-32.3h11.8v16.1c0,5.6,1.9,7.4,6.6,7.4h4.6c2.4,0,3.6.8,3.6,3.4v3.9c0,2.5-1.2,3.4-3.6,3.4h-5.4c-3,0-4.7-.7-5.9-2.6v2.5c-.3,15.1-7.4,20.9-28.2,20.9v-10.7c13.8.1,16.6-3.1,16.6-11.8Z' />
                    <path d='m232,115.7c0-1.9.1-2.9.3-4.6-1.2,1.9-3.2,2.6-6.3,2.6h-4.9c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h4.7c4.2,0,6-1.1,6.8-5.5.5-3.2,1.3-6.7,2.5-10.8h11.5c-2,9.6-3,18.6-3,26.5s2.1,10.6,9.9,10.6v-1.8c0-13.3,4.9-19.1,15.1-19.1h8.3c2.6,0,3.6.9,3.6,3.4v3.9c0,2.4-1,3.4-3.6,3.4h-6.9c-3.5,0-5.2,2.5-5.2,8.6v12.1h-12.4c-13.7,0-20.4-5.8-20.4-18.7Z' />
                    <path d='m291.8,110.1c-2.7,2.4-6.6,3.7-11.1,3.7h-9.5c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h6.7c5.7,0,6.7-1.5,5.8-5.5-.6-3.1-1.6-7.3-2.7-12.4h11.1c1.1,6,2,9.6,2.7,12.6,1.1,4.3,2.8,6.1,5.5,6.1s4.4-2.1,4.4-8.4v-15.9h11.9v16.4c0,2.4-.2,4.2-.6,6,1.9,1.2,4,1.8,5.8,1.8,4.3,0,6.6-2.7,6.6-9.6v-20.7h11.9v23.9c0,11.1-5.7,17.6-14.8,17.6s-10.1-2.3-12.9-5.3c-2.1,3.3-5.7,5.3-9.7,5.3s-8.3-2.3-11.1-4.9Zm6.9-44.4c0-2.6,1.6-4,4.6-4s4.6,1.4,4.6,4v1.6c0,2.6-1.7,4-4.6,4s-4.6-1.4-4.6-4v-1.6Zm6-11.3c0-2.7,1.6-4.1,4.6-4.1s4.7,1.4,4.7,4.1v1.5c0,2.6-1.7,4-4.7,4s-4.6-1.4-4.6-4v-1.5Zm6.2,11.3c0-2.6,1.7-4,4.3-4s4.8,1.4,4.8,4v1.6c0,2.6-1.7,4-4.8,4s-4.3-1.4-4.3-4v-1.6Z' />
                    <path d='m358.6,121c0-12.1,6.6-17.9,22.1-17.9h36.9c2.4,0,3.6.8,3.6,3.4v3.9c0,2.5-1.2,3.4-3.6,3.4h-38.6c-6.3,0-8.7,1.9-8.7,7s2.5,7.6,12.8,7.6h7.4c8.2,0,17.5-.9,24.4-2.6v10.3c-6.8,1.6-15.9,2.4-25.4,2.4h-7.1c-17.6,0-23.9-6.6-23.9-17.4Z' />
                    <path d='m435.4,110.1c-2.7,2.4-6.6,3.7-11.1,3.7h-9.5c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h6.7c5.7,0,6.7-1.5,5.8-5.5-.6-3.1-1.6-7.3-2.7-12.4h11.1c1.1,6,2,9.6,2.7,12.6,1.1,4.3,2.8,6.1,5.5,6.1s4.4-2.1,4.4-8.4v-15.9h11.9v16.4c0,2.4-.2,4.2-.6,6,1.9,1.2,4,1.8,5.8,1.8,4.3,0,6.6-2.7,6.6-9.6v-20.7h11.9v23.9c0,11.1-5.7,17.6-14.8,17.6s-10.1-2.3-12.9-5.3c-2.1,3.3-5.7,5.3-9.7,5.3s-8.3-2.3-11.1-4.9Z' />
                    <path d='m514.5,112v-32.3h11.8v16.1c0,5.6,1.9,7.4,6.6,7.4h4.6c2.4,0,3.6.8,3.6,3.4v3.9c0,2.5-1.2,3.4-3.6,3.4h-5.4c-3,0-4.7-.7-5.9-2.6v2.5c-.3,15.1-7.4,20.9-28.2,20.9v-10.7c13.8.1,16.6-3.1,16.6-11.8Z' />
                    <path d='m542.7,115.7c0-1.9.1-2.9.3-4.6-1.2,1.9-3.2,2.6-6.3,2.6h-4.9c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h4.7c4.2,0,6-1.1,6.8-5.5.5-3.2,1.3-6.7,2.5-10.8h11.5c-2,9.6-3,18.6-3,26.5s2.1,10.6,9.9,10.6v-1.8c0-13.3,4.9-19.1,15.1-19.1h8.3c2.6,0,3.6.9,3.6,3.4v3.9c0,2.4-1,3.4-3.6,3.4h-6.9c-3.5,0-5.2,2.5-5.2,8.6v12.1h-12.4c-13.7,0-20.4-5.8-20.4-18.7Z' />
                    <path d='m602.6,110.1c-2.7,2.4-6.6,3.7-11.1,3.7h-9.5c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h6.7c5.7,0,6.7-1.5,5.8-5.5-.6-3.1-1.6-7.3-2.7-12.4h11.1c1.1,6,2,9.6,2.7,12.6,1.1,4.3,2.8,6.1,5.5,6.1s4.4-2.1,4.4-8.4v-15.9h11.9v16.4c0,2.4-.2,4.2-.6,6,1.9,1.2,4,1.8,5.8,1.8,4.3,0,6.6-2.7,6.6-9.6v-20.7h11.9v23.9c0,11.1-5.7,17.6-14.8,17.6s-10.1-2.3-12.9-5.3c-2.1,3.3-5.7,5.3-9.7,5.3s-8.3-2.3-11.1-4.9Zm6.9-44.4c0-2.6,1.6-4,4.6-4s4.6,1.4,4.6,4v1.6c0,2.6-1.7,4-4.6,4s-4.6-1.4-4.6-4v-1.6Zm6-11.3c0-2.7,1.6-4.1,4.6-4.1s4.7,1.4,4.7,4.1v1.5c0,2.6-1.7,4-4.7,4s-4.6-1.4-4.6-4v-1.5Zm6.2,11.3c0-2.6,1.7-4,4.3-4s4.8,1.4,4.8,4v1.6c0,2.6-1.7,4-4.8,4s-4.3-1.4-4.3-4v-1.6Z' />
                </g>
            </svg>
        );
    }

    return (
        <svg
            preserveAspectRatio='xMinYMid meet'
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 651.1 138.5'>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
            <g>
                <path
                    className='cls-4'
                    d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.3,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                />
                <rect x='35.7' y='59.7' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                <path
                    className='cls-3'
                    d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                />
            </g>
            <g>
                <path
                    className='cls-2'
                    d='m327.7,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                />
                <path
                    className='cls-1'
                    d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2'
                />
                <path
                    className='cls-1'
                    d='m238.6,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z'
                />
                <path
                    className='cls-1'
                    d='m265.3,21.9l-.2-2.7h-6v24.8h6.5v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9'
                />
                <polygon
                    className='cls-1'
                    points='307.6 19.2 301.5 37.1 295.5 19.2 288.6 19.2 297.7 44.1 305.4 44.1 314.5 19.2 307.6 19.2'
                />
                <g>
                    <rect className='cls-1' x='187.2' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='187.2' y='9.6' width='6.5' height='6.5' />
                </g>
                <g>
                    <rect className='cls-1' x='278.8' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='278.8' y='9.6' width='6.5' height='6.5' />
                </g>
            </g>
            <g>
                <path
                    className='cls-1'
                    d='m203.7,112v-32.3h11.8v16.1c0,5.6,1.9,7.4,6.6,7.4h4.6c2.4,0,3.6.8,3.6,3.4v3.9c0,2.5-1.2,3.4-3.6,3.4h-5.4c-3,0-4.7-.7-5.9-2.6v2.5c-.3,15.1-7.4,20.9-28.2,20.9v-10.7c13.8.1,16.6-3.1,16.6-11.8Z'
                />
                <path
                    className='cls-1'
                    d='m232,115.7c0-1.9.1-2.9.3-4.6-1.2,1.9-3.2,2.6-6.3,2.6h-4.9c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h4.7c4.2,0,6-1.1,6.8-5.5.5-3.2,1.3-6.7,2.5-10.8h11.5c-2,9.6-3,18.6-3,26.5s2.1,10.6,9.9,10.6v-1.8c0-13.3,4.9-19.1,15.1-19.1h8.3c2.6,0,3.6.9,3.6,3.4v3.9c0,2.4-1,3.4-3.6,3.4h-6.9c-3.5,0-5.2,2.5-5.2,8.6v12.1h-12.4c-13.7,0-20.4-5.8-20.4-18.7Z'
                />
                <path
                    className='cls-1'
                    d='m291.8,110.1c-2.7,2.4-6.6,3.7-11.1,3.7h-9.5c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h6.7c5.7,0,6.7-1.5,5.8-5.5-.6-3.1-1.6-7.3-2.7-12.4h11.1c1.1,6,2,9.6,2.7,12.6,1.1,4.3,2.8,6.1,5.5,6.1s4.4-2.1,4.4-8.4v-15.9h11.9v16.4c0,2.4-.2,4.2-.6,6,1.9,1.2,4,1.8,5.8,1.8,4.3,0,6.6-2.7,6.6-9.6v-20.7h11.9v23.9c0,11.1-5.7,17.6-14.8,17.6s-10.1-2.3-12.9-5.3c-2.1,3.3-5.7,5.3-9.7,5.3s-8.3-2.3-11.1-4.9Zm6.9-44.4c0-2.6,1.6-4,4.6-4s4.6,1.4,4.6,4v1.6c0,2.6-1.7,4-4.6,4s-4.6-1.4-4.6-4v-1.6Zm6-11.3c0-2.7,1.6-4.1,4.6-4.1s4.7,1.4,4.7,4.1v1.5c0,2.6-1.7,4-4.7,4s-4.6-1.4-4.6-4v-1.5Zm6.2,11.3c0-2.6,1.7-4,4.3-4s4.8,1.4,4.8,4v1.6c0,2.6-1.7,4-4.8,4s-4.3-1.4-4.3-4v-1.6Z'
                />
                <path
                    className='cls-1'
                    d='m358.6,121c0-12.1,6.6-17.9,22.1-17.9h36.9c2.4,0,3.6.8,3.6,3.4v3.9c0,2.5-1.2,3.4-3.6,3.4h-38.6c-6.3,0-8.7,1.9-8.7,7s2.5,7.6,12.8,7.6h7.4c8.2,0,17.5-.9,24.4-2.6v10.3c-6.8,1.6-15.9,2.4-25.4,2.4h-7.1c-17.6,0-23.9-6.6-23.9-17.4Z'
                />
                <path
                    className='cls-1'
                    d='m435.4,110.1c-2.7,2.4-6.6,3.7-11.1,3.7h-9.5c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h6.7c5.7,0,6.7-1.5,5.8-5.5-.6-3.1-1.6-7.3-2.7-12.4h11.1c1.1,6,2,9.6,2.7,12.6,1.1,4.3,2.8,6.1,5.5,6.1s4.4-2.1,4.4-8.4v-15.9h11.9v16.4c0,2.4-.2,4.2-.6,6,1.9,1.2,4,1.8,5.8,1.8,4.3,0,6.6-2.7,6.6-9.6v-20.7h11.9v23.9c0,11.1-5.7,17.6-14.8,17.6s-10.1-2.3-12.9-5.3c-2.1,3.3-5.7,5.3-9.7,5.3s-8.3-2.3-11.1-4.9Z'
                />
                <path
                    className='cls-1'
                    d='m514.5,112v-32.3h11.8v16.1c0,5.6,1.9,7.4,6.6,7.4h4.6c2.4,0,3.6.8,3.6,3.4v3.9c0,2.5-1.2,3.4-3.6,3.4h-5.4c-3,0-4.7-.7-5.9-2.6v2.5c-.3,15.1-7.4,20.9-28.2,20.9v-10.7c13.8.1,16.6-3.1,16.6-11.8Z'
                />
                <path
                    className='cls-1'
                    d='m542.7,115.7c0-1.9.1-2.9.3-4.6-1.2,1.9-3.2,2.6-6.3,2.6h-4.9c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h4.7c4.2,0,6-1.1,6.8-5.5.5-3.2,1.3-6.7,2.5-10.8h11.5c-2,9.6-3,18.6-3,26.5s2.1,10.6,9.9,10.6v-1.8c0-13.3,4.9-19.1,15.1-19.1h8.3c2.6,0,3.6.9,3.6,3.4v3.9c0,2.4-1,3.4-3.6,3.4h-6.9c-3.5,0-5.2,2.5-5.2,8.6v12.1h-12.4c-13.7,0-20.4-5.8-20.4-18.7Z'
                />
                <path
                    className='cls-1'
                    d='m602.6,110.1c-2.7,2.4-6.6,3.7-11.1,3.7h-9.5c-2.4,0-3.6-.8-3.6-3.4v-3.9c0-2.5,1.2-3.4,3.6-3.4h6.7c5.7,0,6.7-1.5,5.8-5.5-.6-3.1-1.6-7.3-2.7-12.4h11.1c1.1,6,2,9.6,2.7,12.6,1.1,4.3,2.8,6.1,5.5,6.1s4.4-2.1,4.4-8.4v-15.9h11.9v16.4c0,2.4-.2,4.2-.6,6,1.9,1.2,4,1.8,5.8,1.8,4.3,0,6.6-2.7,6.6-9.6v-20.7h11.9v23.9c0,11.1-5.7,17.6-14.8,17.6s-10.1-2.3-12.9-5.3c-2.1,3.3-5.7,5.3-9.7,5.3s-8.3-2.3-11.1-4.9Zm6.9-44.4c0-2.6,1.6-4,4.6-4s4.6,1.4,4.6,4v1.6c0,2.6-1.7,4-4.6,4s-4.6-1.4-4.6-4v-1.6Zm6-11.3c0-2.7,1.6-4.1,4.6-4.1s4.7,1.4,4.7,4.1v1.5c0,2.6-1.7,4-4.7,4s-4.6-1.4-4.6-4v-1.5Zm6.2,11.3c0-2.6,1.7-4,4.3-4s4.8,1.4,4.8,4v1.6c0,2.6-1.7,4-4.8,4s-4.3-1.4-4.3-4v-1.6Z'
                />
            </g>
        </svg>
    );
};

export default LogoIntercityUr;
