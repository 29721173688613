import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityAr: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                preserveAspectRatio='xMinYMid meet'
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 340.5 138.5'>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
                <g>
                    <path
                        className='cls-1'
                        d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.2,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                    />
                    <rect x='35.7' y='59.6' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                    <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                    <path
                        className='cls-2'
                        d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                    />
                </g>
                <g>
                    <path
                        className='cls-2'
                        d='m327.6,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                    />
                    <path d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2' />
                    <path d='m238.5,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z' />
                    <path d='m265.2,21.9l-.2-2.7h-6v24.8h6.4v-10.8c0-6.1,4.6-8.7,9.7-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9' />
                    <polygon points='307.6 19.2 301.5 37.1 295.4 19.2 288.6 19.2 297.6 44.1 305.4 44.1 314.4 19.2 307.6 19.2' />
                    <g>
                        <rect x='187.1' y='19.2' width='6.5' height='24.8' />
                        <rect x='187.1' y='9.6' width='6.5' height='6.5' />
                    </g>
                    <g>
                        <rect x='278.8' y='19.2' width='6.4' height='24.8' />
                        <rect x='278.8' y='9.6' width='6.5' height='6.5' />
                    </g>
                </g>
                <g>
                    <path d='m204.7,114.8v-32.2h12.4v17c0,5.9,2,7.8,7,7.8h4.8c2.5,0,3.8.9,3.8,3.6v4.1c0,2.7-1.3,3.6-3.8,3.6h-5.7c-3.2,0-4.9-.8-6.2-2.8v.8c-.3,15.9-7.8,22-29.8,22v-11.3c14.5.1,17.4-3.2,17.4-12.4Z' />
                    <path d='m219.2,115v-4.1c0-2.7,1.3-3.6,3.8-3.6h11.6c-3.6-3-5-7.1-5-12v-1.6c0-10.5,6.2-16.4,18.5-16.4s18.6,5.9,18.6,16.4v1.6c0,4.9-1.5,9-5,12h11.4c2.5,0,3.8.9,3.8,3.6v4.1c0,2.7-1.3,3.6-3.8,3.6h-13.2c-4.4,0-8.3-.9-11.9-2.7-3.6,1.8-7.4,2.7-11.8,2.7h-13.3c-2.5,0-3.8-.9-3.8-3.6Zm28.9-9.2c4-.7,5.9-3.8,5.9-7.7v-3.7c0-4.5-1.6-6.8-5.9-6.8s-5.9,2.2-5.9,6.8v3.4c0,3.9,1.9,7.2,5.9,7.9Zm-4.8-43.7c0-3.2,2-4.7,5.3-4.7s5.3,1.4,5.3,4.7v1.9c0,3-1.9,4.7-5.3,4.7s-5.3-1.7-5.3-4.7v-1.9Z' />
                    <path d='m288.9,114.6c-2.8,2.6-7,3.9-11.7,3.9h-10c-2.5,0-3.8-.9-3.8-3.6v-4.1c0-2.7,1.3-3.6,3.8-3.6h7.1c6,0,7.1-1.6,6.1-5.8-.7-3.2-1.6-7.7-2.8-13.1h11.7c1.2,6.3,2.1,10.1,2.8,13.3,1.2,4.5,2.9,6.4,5.8,6.4s4.7-2.2,4.7-8.9v-16.8h12.5v17.3c0,2.6-.2,4.4-.7,6.3,2,1.2,4.3,1.9,6.1,1.9,4.6,0,7-2.9,7-10.1v-21.9h12.5v25.2c0,11.7-6,18.5-15.6,18.5s-10.7-2.4-13.6-5.5c-2.2,3.4-6,5.5-10.2,5.5s-8.7-2.4-11.7-5.2Z' />
                </g>
            </svg>
        );
    }

    return (
        <svg
            preserveAspectRatio='xMinYMid meet'
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 340.5 138.5'>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
            <g>
                <path
                    className='cls-4'
                    d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.2,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                />
                <rect x='35.7' y='59.6' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                <path
                    className='cls-3'
                    d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                />
            </g>
            <g>
                <path
                    className='cls-2'
                    d='m327.6,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                />
                <path
                    className='cls-1'
                    d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2'
                />
                <path
                    className='cls-1'
                    d='m238.5,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z'
                />
                <path
                    className='cls-1'
                    d='m265.2,21.9l-.2-2.7h-6v24.8h6.4v-10.8c0-6.1,4.6-8.7,9.7-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9'
                />
                <polygon
                    className='cls-1'
                    points='307.6 19.2 301.5 37.1 295.4 19.2 288.6 19.2 297.6 44.1 305.4 44.1 314.4 19.2 307.6 19.2'
                />
                <g>
                    <rect className='cls-1' x='187.1' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='187.1' y='9.6' width='6.5' height='6.5' />
                </g>
                <g>
                    <rect className='cls-1' x='278.8' y='19.2' width='6.4' height='24.8' />
                    <rect className='cls-1' x='278.8' y='9.6' width='6.5' height='6.5' />
                </g>
            </g>
            <g>
                <path
                    className='cls-1'
                    d='m204.7,114.8v-32.2h12.4v17c0,5.9,2,7.8,7,7.8h4.8c2.5,0,3.8.9,3.8,3.6v4.1c0,2.7-1.3,3.6-3.8,3.6h-5.7c-3.2,0-4.9-.8-6.2-2.8v.8c-.3,15.9-7.8,22-29.8,22v-11.3c14.5.1,17.4-3.2,17.4-12.4Z'
                />
                <path
                    className='cls-1'
                    d='m219.2,115v-4.1c0-2.7,1.3-3.6,3.8-3.6h11.6c-3.6-3-5-7.1-5-12v-1.6c0-10.5,6.2-16.4,18.5-16.4s18.6,5.9,18.6,16.4v1.6c0,4.9-1.5,9-5,12h11.4c2.5,0,3.8.9,3.8,3.6v4.1c0,2.7-1.3,3.6-3.8,3.6h-13.2c-4.4,0-8.3-.9-11.9-2.7-3.6,1.8-7.4,2.7-11.8,2.7h-13.3c-2.5,0-3.8-.9-3.8-3.6Zm28.9-9.2c4-.7,5.9-3.8,5.9-7.7v-3.7c0-4.5-1.6-6.8-5.9-6.8s-5.9,2.2-5.9,6.8v3.4c0,3.9,1.9,7.2,5.9,7.9Zm-4.8-43.7c0-3.2,2-4.7,5.3-4.7s5.3,1.4,5.3,4.7v1.9c0,3-1.9,4.7-5.3,4.7s-5.3-1.7-5.3-4.7v-1.9Z'
                />
                <path
                    className='cls-1'
                    d='m288.9,114.6c-2.8,2.6-7,3.9-11.7,3.9h-10c-2.5,0-3.8-.9-3.8-3.6v-4.1c0-2.7,1.3-3.6,3.8-3.6h7.1c6,0,7.1-1.6,6.1-5.8-.7-3.2-1.6-7.7-2.8-13.1h11.7c1.2,6.3,2.1,10.1,2.8,13.3,1.2,4.5,2.9,6.4,5.8,6.4s4.7-2.2,4.7-8.9v-16.8h12.5v17.3c0,2.6-.2,4.4-.7,6.3,2,1.2,4.3,1.9,6.1,1.9,4.6,0,7-2.9,7-10.1v-21.9h12.5v25.2c0,11.7-6,18.5-15.6,18.5s-10.7-2.4-13.6-5.5c-2.2,3.4-6,5.5-10.2,5.5s-8.7-2.4-11.7-5.2Z'
                />
            </g>
        </svg>
    );
};

export default LogoIntercityAr;
