import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityRu: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                preserveAspectRatio='xMinYMid meet'
                viewBox='0 0 160 40'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M0 19.928C0 14.3947 0 11.6285 0.772067 9.4028C2.17504 5.35607 5.35607 2.17504 9.4028 0.772064C11.6285 -3.8147e-06 14.3947 -7.62939e-06 19.928 -7.62939e-06C25.4612 -7.62939e-06 28.2274 -3.8147e-06 30.4532 0.772064C34.5008 2.17504 37.6809 5.35607 39.0842 9.4028C39.856 11.6285 39.856 14.3947 39.856 19.928C39.856 25.4612 39.856 28.2274 39.0842 30.4532C37.6809 34.4999 34.5008 37.6809 30.4532 39.0842C28.2274 39.856 25.4612 39.856 19.928 39.856C14.3947 39.856 11.6285 39.856 9.4028 39.0842C5.35607 37.6809 2.17504 34.4999 0.772067 30.4532C0 28.2274 0 25.4612 0 19.928Z'
                    fill='#A7E92F'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.0328 17.1663C10.5794 17.1663 10.2783 17.4677 10.2783 17.9208V29.7145C10.2783 30.1679 10.5794 30.4692 11.0328 30.4692H14.2153C14.6684 30.4692 14.9698 30.1679 14.9698 29.7145V17.9208C14.9698 17.4677 14.6684 17.1663 14.2153 17.1663H11.0328Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.0328 9.37271C10.5794 9.37271 10.2783 9.67405 10.2783 10.1272V13.1832C10.2783 13.6375 10.5794 13.938 11.0328 13.938H14.2153C14.6684 13.938 14.9698 13.6375 14.9698 13.1832V10.1272C14.9698 9.67405 14.6684 9.37271 14.2153 9.37271H11.0328Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.863 25.913H19.3238C18.8704 25.913 18.5693 26.2143 18.5693 26.6675V29.7137C18.5693 30.1671 18.8704 30.4653 19.3238 30.4684L21.863 30.4849C36.3321 30.4849 36.3321 9.37113 21.863 9.37113L19.3238 9.38755C18.8704 9.39071 18.5693 9.68888 18.5693 10.1423V13.1885C18.5693 13.6417 18.8704 13.943 19.3238 13.943H21.863C30.0481 13.943 30.0481 25.913 21.863 25.913Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M96.1307 8.33703C95.9484 7.57246 95.1227 7.01098 94.2841 7.01098C93.4455 7.01098 92.644 7.54826 92.4375 8.33703H96.1307ZM94.2899 5.33202C96.7294 5.33202 98.3665 7.24721 97.9042 9.60173L92.3833 9.6049C92.5032 10.5979 93.3297 11.2023 94.2564 11.2012C95.0997 11.2003 95.7968 10.8174 96.1722 10.0422L97.74 10.8437C97.1114 12.0749 95.7893 12.8833 94.2899 12.8833C92.0344 12.8712 90.4658 11.1928 90.4658 9.10766C90.4658 7.02222 92.0465 5.34412 94.2899 5.33202Z'
                    fill='black'
                />
                <path
                    d='M58.9281 6.27634L58.8742 5.53884H57.1523V12.6802H59.0088V9.00623C59.0088 6.81621 62.0345 6.64854 62.0345 8.52398V12.6796H63.8912V8.35804C63.8912 5.15799 60.3616 4.76649 58.9281 6.27634Z'
                    fill='black'
                />
                <path
                    d='M68.65 3.59196H65.3652V12.6802H68.65C71.2986 12.6802 73.2979 10.7324 73.2979 8.13535C73.2979 5.53855 71.2986 3.59081 68.65 3.59081V3.59196ZM68.65 10.9142H67.2222V5.35706H68.65C70.2857 5.35706 71.4415 6.4996 71.4415 8.13593C71.4415 9.77196 70.2863 10.9145 68.65 10.9145V10.9142Z'
                    fill='black'
                />
                <path
                    d='M76.3334 6.3135L76.2795 5.53942H74.5576V12.6802H76.4137V9.5775C76.4137 7.82595 77.7467 7.07261 79.2197 7.39641V5.48785C78.3226 5.26401 77.0055 5.40171 76.3334 6.3135Z'
                    fill='black'
                />
                <path
                    d='M88.5188 5.53942L86.7644 10.6679L85.0232 5.53942H83.0498L85.6604 12.6802H87.8816L90.4922 5.53942H88.5188Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M53.8584 12.6802H55.7154V5.53942H53.8584V12.6802Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M53.8584 4.6333H55.7154V2.77631H53.8584V4.6333Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M80.2324 12.6802H82.0886V5.53942H80.2324V12.6802Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M80.2324 4.6333H82.0894V2.77631H80.2324V4.6333Z'
                    fill='black'
                />
                <path
                    d='M65.0663 34.0903V25.6422L62.4401 34.0903H59.3432L56.7418 25.6918V34.0903H53.8682V21.9012H58.3522L60.9536 30.5722L63.555 21.9012H67.9402V34.0903H65.0663Z'
                    fill='black'
                />
                <path
                    d='M77.578 26.8049C77.5285 24.9963 76.3393 24.1292 75.0757 24.1292C73.7874 24.1292 72.7221 25.1202 72.5982 26.8049H77.578ZM72.4991 28.8365C72.5982 30.6699 73.6388 31.8591 75.2244 31.8591C76.265 31.8591 77.1816 31.3385 77.4046 30.4961H80.7245C79.9812 32.9987 77.9744 34.4106 75.373 34.4106C71.3099 34.4106 69.2783 32.1809 69.2783 27.7712C69.2783 24.0053 71.4338 21.5773 75.1748 21.5773C78.9159 21.5773 80.8976 24.0053 80.8976 28.8365H72.4991Z'
                    fill='black'
                />
                <path
                    d='M89.842 26.5586L92.8398 21.9012H96.5065L92.0965 27.872L96.68 34.0903H92.8893L89.842 29.5317V34.0903H86.8689V29.5317L83.8219 34.0903H80.0312L84.6144 27.872L80.2047 21.9012H83.8711L86.8689 26.5586V21.9012H89.842V26.5586Z'
                    fill='black'
                />
                <path d='M97.8691 34.0903V21.9012H105.624V24.4778H101.016V34.0903H97.8691Z' fill='black' />
                <path
                    d='M115.137 27.9941C115.137 25.5166 114.146 24.3029 112.189 24.3029C110.232 24.3029 109.241 25.5166 109.241 27.9941C109.241 30.4717 110.232 31.7101 112.189 31.7101C114.146 31.7101 115.137 30.4717 115.137 27.9941ZM105.921 27.9941C105.921 24.1292 108.324 21.5776 112.189 21.5776C116.004 21.5776 118.383 24.1044 118.383 27.9941C118.383 31.8591 115.979 34.4106 112.189 34.4106C108.25 34.4106 105.921 31.7845 105.921 27.9941Z'
                    fill='black'
                />
                <path
                    d='M128.317 27.8976C128.317 25.7174 127.178 24.2061 125.567 24.2061C124.031 24.2061 122.842 25.643 122.842 27.6994C122.842 30.3503 123.759 31.6631 125.543 31.6631C127.425 31.6631 128.317 30.3999 128.317 27.8976ZM119.795 39.8638V21.902H122.892L122.916 23.3885C123.684 22.1497 124.824 21.5799 126.311 21.5799C129.383 21.5799 131.588 24.0079 131.588 28.1203C131.588 31.8121 129.73 34.4132 126.583 34.4132C125.072 34.4132 123.883 33.7941 122.966 32.5055V39.8638H119.795Z'
                    fill='black'
                />
                <path
                    d='M141.944 27.9941C141.944 25.5166 140.953 24.3029 138.995 24.3029C137.038 24.3029 136.047 25.5166 136.047 27.9941C136.047 30.4717 137.038 31.7101 138.995 31.7101C140.953 31.7101 141.944 30.4717 141.944 27.9941ZM132.728 27.9941C132.728 24.1292 135.131 21.5776 138.995 21.5776C142.811 21.5776 145.189 24.1044 145.189 27.9941C145.189 31.8591 142.786 34.4106 138.995 34.4106C135.056 34.4106 132.728 31.7845 132.728 27.9941Z'
                    fill='black'
                />
                <path
                    d='M150.912 24.4735L150.789 26.6537C150.689 28.3137 150.367 30.5432 149.822 31.5097H154.257V24.4735H150.912ZM145.264 37.7778V31.5097H145.784C147.419 31.5097 148.088 30.816 148.336 26.4803L148.608 21.8972H157.404V31.5097H159.286V37.7778H156.957V34.086H147.593V37.7778H145.264Z'
                    fill='black'
                />
            </svg>
        );
    }

    return (
        <svg preserveAspectRatio='xMinYMid meet' viewBox='0 0 160 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1730_66753)'>
                <path
                    d='M0 19.9987C0 14.4462 0 11.6704 0.774756 9.4369C2.18261 5.37608 5.37472 2.18398 9.43554 0.776119C11.669 0.00136185 14.4449 0.00136185 19.9974 0.00136185C25.5499 0.00136185 28.3257 0.00136185 30.5592 0.776119C34.6209 2.18398 37.8121 5.37608 39.2203 9.4369C39.9947 11.6704 39.9947 14.4462 39.9947 19.9987C39.9947 25.5512 39.9947 28.3271 39.2203 30.5606C37.8121 34.6214 34.6209 37.8135 30.5592 39.2216C28.3257 39.9961 25.5499 39.9961 19.9974 39.9961C14.4449 39.9961 11.669 39.9961 9.43554 39.2216C5.37472 37.8135 2.18261 34.6214 0.774756 30.5606C0 28.3271 0 25.5512 0 19.9987Z'
                    fill='#A7E92F'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.0706 17.225C10.6156 17.225 10.3135 17.5274 10.3135 17.9821V29.8168C10.3135 30.2718 10.6156 30.5742 11.0706 30.5742H14.2642C14.7189 30.5742 15.0213 30.2718 15.0213 29.8168V17.9821C15.0213 17.5274 14.7189 17.225 14.2642 17.225H11.0706Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.0706 9.4032C10.6156 9.4032 10.3135 9.70558 10.3135 10.1603V13.227C10.3135 13.6829 10.6156 13.9844 11.0706 13.9844H14.2642C14.7189 13.9844 15.0213 13.6829 15.0213 13.227V10.1603C15.0213 9.70558 14.7189 9.4032 14.2642 9.4032H11.0706Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.938 26.002H19.3899C18.9349 26.002 18.6328 26.3044 18.6328 26.7591V29.816C18.6328 30.271 18.9349 30.5702 19.3899 30.5734L21.938 30.5898C36.4574 30.5898 36.4574 9.40259 21.938 9.40259L19.3899 9.41907C18.9349 9.42225 18.6328 9.72145 18.6328 10.1765V13.2333C18.6328 13.688 18.9349 13.9904 19.3899 13.9904H21.938C30.1515 13.9904 30.1515 26.002 21.938 26.002Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M96.4659 8.36759C96.2829 7.60035 95.4544 7.03692 94.6128 7.03692C93.7713 7.03692 92.967 7.57607 92.7598 8.36759H96.4659ZM94.6186 5.35212C97.0666 5.35212 98.7095 7.27397 98.2455 9.63669L92.7054 9.63987C92.8257 10.6364 93.6551 11.2429 94.5851 11.2417C95.4312 11.2408 96.1308 10.8566 96.5075 10.0787L98.0807 10.8829C97.4499 12.1185 96.1233 12.9297 94.6186 12.9297C92.3553 12.9175 90.7812 11.2333 90.7812 9.1409C90.7812 7.04819 92.3675 5.36426 94.6186 5.35212Z'
                    fill='white'
                />
                <path
                    d='M59.1335 6.29652L59.0794 5.55646H57.3516V12.7227H59.2144V9.03592C59.2144 6.83827 62.2507 6.67002 62.2507 8.55199V12.7221H64.1139V8.38547C64.1139 5.17428 60.572 4.78141 59.1335 6.29652Z'
                    fill='white'
                />
                <path
                    d='M68.889 3.6028H65.5928V12.7227H68.889C71.5468 12.7227 73.5531 10.7681 73.5531 8.16201C73.5531 5.55617 71.5468 3.60164 68.889 3.60164V3.6028ZM68.889 10.9505H67.4562V5.37404H68.889C70.5304 5.37404 71.6902 6.52056 71.6902 8.16258C71.6902 9.80431 70.531 10.9508 68.889 10.9508V10.9505Z'
                    fill='white'
                />
                <path
                    d='M76.5983 6.33381L76.5443 5.55703H74.8164V12.7227H76.679V9.60918C76.679 7.85152 78.0166 7.09556 79.4947 7.4205V5.50529C78.5945 5.28067 77.2728 5.41885 76.5983 6.33381Z'
                    fill='white'
                />
                <path
                    d='M88.8269 5.55703L87.0664 10.7034L85.3191 5.55703H83.3389L85.9586 12.7227H88.1874L90.8072 5.55703H88.8269Z'
                    fill='white'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M54.0459 12.7227H55.9093V5.55704H54.0459V12.7227Z'
                    fill='white'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M54.0459 4.64459H55.9093V2.78114H54.0459V4.64459Z'
                    fill='white'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M80.5118 12.7227H82.3744V5.55704H80.5118V12.7227Z'
                    fill='white'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M80.5118 4.64459H82.3753V2.78114H80.5118V4.64459Z'
                    fill='white'
                />
                <path
                    d='M65.2928 34.207V25.7295L62.6578 34.207H59.5501L56.9393 25.7793V34.207H54.0557V21.9754H58.5553L61.1661 30.6767L63.7762 21.9754H68.177V34.207H65.2928Z'
                    fill='white'
                />
                <path
                    d='M77.8482 26.899C77.7984 25.0841 76.6051 24.214 75.3371 24.214C74.0446 24.214 72.9756 25.2085 72.8513 26.899H77.8482ZM72.7515 28.9377C72.8513 30.7774 73.8952 31.9708 75.4863 31.9708C76.5305 31.9708 77.4504 31.4484 77.6741 30.6031H81.0056C80.2597 33.1144 78.2459 34.5312 75.6358 34.5312C71.5582 34.5312 69.5195 32.2937 69.5195 27.8686C69.5195 24.0897 71.6828 21.6533 75.4366 21.6533C79.1907 21.6533 81.1793 24.0897 81.1793 28.9377H72.7515Z'
                    fill='white'
                />
                <path
                    d='M90.1547 26.6491L93.1627 21.9754H96.8425L92.4172 27.9671L97.0163 34.207H93.2127L90.1547 29.6325V34.207H87.1714V29.6325L84.1137 34.207H80.3096L84.909 27.9671L80.4839 21.9754H84.1631L87.1714 26.6491V21.9754H90.1547V26.6491Z'
                    fill='white'
                />
                <path d='M98.21 34.207V21.9754H105.991V24.561H101.367V34.207H98.21Z' fill='white' />
                <path
                    d='M115.538 28.0924C115.538 25.6062 114.544 24.3883 112.58 24.3883C110.616 24.3883 109.621 25.6062 109.621 28.0924C109.621 30.5786 110.616 31.8213 112.58 31.8213C114.544 31.8213 115.538 30.5786 115.538 28.0924ZM106.29 28.0924C106.29 24.214 108.702 21.6535 112.58 21.6535C116.408 21.6535 118.795 24.1891 118.795 28.0924C118.795 31.9708 116.384 34.5312 112.58 34.5312C108.627 34.5312 106.29 31.8959 106.29 28.0924Z'
                    fill='white'
                />
                <path
                    d='M128.764 27.9922C128.764 25.8043 127.621 24.2878 126.005 24.2878C124.463 24.2878 123.27 25.7298 123.27 27.7933C123.27 30.4535 124.19 31.7708 125.98 31.7708C127.869 31.7708 128.764 30.5032 128.764 27.9922ZM120.212 40V21.9757H123.32L123.344 23.4674C124.115 22.2243 125.259 21.6525 126.751 21.6525C129.833 21.6525 132.046 24.0889 132.046 28.2156C132.046 31.9203 130.181 34.5305 127.024 34.5305C125.507 34.5305 124.314 33.9092 123.394 32.6161V40H120.212Z'
                    fill='white'
                />
                <path
                    d='M142.438 28.0924C142.438 25.6062 141.443 24.3883 139.479 24.3883C137.515 24.3883 136.521 25.6062 136.521 28.0924C136.521 30.5786 137.515 31.8213 139.479 31.8213C141.443 31.8213 142.438 30.5786 142.438 28.0924ZM133.189 28.0924C133.189 24.214 135.601 21.6535 139.479 21.6535C143.308 21.6535 145.695 24.1891 145.695 28.0924C145.695 31.9708 143.283 34.5312 139.479 34.5312C135.526 34.5312 133.189 31.8959 133.189 28.0924Z'
                    fill='white'
                />
                <path
                    d='M151.438 24.5634L151.313 26.7512C151.214 28.4169 150.891 30.6542 150.344 31.6241H154.794V24.5634H151.438ZM145.77 37.9141V31.6241H146.291C147.932 31.6241 148.603 30.928 148.852 26.5772L149.126 21.9781H157.951V31.6241H159.841V37.9141H157.504V34.2094H148.107V37.9141H145.77Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_1730_66753'>
                    <rect width='160' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LogoIntercityRu;
