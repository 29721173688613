import axios from 'axios';
import { logger } from '@indriver/elastic-logger';
import { INearCountry } from 'common/api/models';
import * as Sentry from '@sentry/nextjs';

export const getCountry = async (): Promise<{ nearCountry: INearCountry }> => {
    return axios
        .get('/api/get-country')
        .then((res) => res.data)
        .catch((err) => {
            Sentry.captureException(err);
            logger.error({ err });
            return err;
        });
};
