import { CountryType } from '@indriver/nova-core';

export interface IRequestAuthCodeResponse {
    locale: string;
    title: string;
    mode: string;
    code: {
        placeholder: string;
        link: string;
        link_text: string;
        length: number;
    };
    resend: {
        text: string;
        timer: string;
        mode: string;
        timeout: number;
    };
    mode_from_request: string;
    phone: string;
    parse_call_status: string;
    whatsapp_installed: boolean;
    phone_without_country_code: string;
    phone_code: string;
    phone_modified_code: string;
    error: {
        text: string;
    };
}

export interface ICheckAuthCodeResponse {
    access_token: string;
    refresh_token: string;
    error: {
        text: string;
        slug: string;
    };
}

export interface ICountry {
    id: number;
    name: string;
}

export interface ICountryWithCode extends ICountry {
    code: CountryType;
}

export interface INearCountry extends ICountryWithCode {
    nearCities: ICity[];
}

export interface ICity {
    id: string;
    name: string;
    radius: string;
    population: string;
    distance: string;
    sub_city: boolean;
    region: {
        id: string;
        name: string;
    };
    country: {
        id: string;
        name: string;
        code: { String: string; Valid: string };
    };
}

export interface IRecommendedCityResponse {
    nearCities: ICity[];
}

export interface IAutocompleteCity {
    currency_code: string;
    country_id: number;
    id: number;
    name: string;
    latitude: number;
    longitude: number;
    payment_types: Record<string, string | number>[];
    sub_name: string;
    timezone: string;
}

export interface IAddress {
    address: string;
    description: string;
    endpoint: string;
    full_address: string;
    latitude: number;
    locality: string;
    longitude: number;
    place_id: string;
}

export interface IAutocompleteAddress {
    address: IAddress;
    city: {
        country_id: number;
        currency_code: string;
        id: number;
        name: string;
        payment_types: [
            {
                description: string;
                description_short: string;
                icon_url: string;
                id: number;
                info: string;
                method: string;
                provider: string;
                type: string;
            },
        ];
        sub_name: string;
        timezone: string;
    };
}

export interface IOrderForm {
    address_from: string;
    address_to: string;
    city_from_id: number;
    city_to_id: number;
    currency_code: string;
    departure_time: {
        is_detailed: boolean;
        timestamp: number;
    };
    full_address_from?: IAddress;
    full_address_to?: IAddress;
    type: string;
    passengers_count: number;
    payment_type_id: number;
    price: number;
    source_ref_id?: string;
    source?: string;
    countries: {
        from_id: number;
        to_id: number;
    };
}

export interface IBid {
    comment: string;
    created_at: number;
    departure_date: number;
    driver: {
        avatar: string;
        birthday: string;
        car_description: string;
        car_description_short: string;
        car_photo: string;
        id: number;
        name: string;
        orders_count: number;
        rating: number;
        ratings_count: number;
    };
    id: string;
    order_id: string;
    price: number;
}

export interface IAcceptedBid {
    comment: string;
    created_at: number;
    departure_date: number;
    driver: {
        avatar: string;
        birthday: string;
        car_description: string;
        car_description_short: string;
        car_photo: string;
        id: number;
        name: string;
        orders_count: number;
        phone: string;
        rating: number;
        ratings_count: number;
    };
    id: string;
    order_id: string;
    price: number;
}

export enum OrderClientStatus {
    published = 'published_passenger',
    accepted = 'accepted_passenger',
    active = 'active_system',
    cancelledPassenger = 'cancelled_passenger',
    cancelledDriver = 'cancelled_driver',
    cancelledSystemExpired = 'cancelled_system_expired',
    cancelledSystemAutodeclined = 'cancelled_system_autodeclined',
    donePassenger = 'done_passenger',
    doneDriver = 'done_driver',
    doneSystemTimeout = 'done_system_timeout',
    deletedModerator = 'deleted_moderator',
}

export interface IPaymentType {
    description: string;
    description_short: string;
    icon_url: string;
    id: number;
    info: string;
    method: string;
    provider: string;
    type: string;
}

export interface IOrder {
    accepted_bid?: IAcceptedBid;
    address_from: string;
    address_to: string;
    can_finish: boolean;
    can_rate: boolean;
    can_chat: boolean;
    city_from: IAutocompleteCity;
    city_to: IAutocompleteCity;
    created_at: number;
    currency_code: string;
    departure_time: { timestamp: number; is_detailed: boolean; is_asap: boolean };
    id: string;
    user_id: number;
    passengers_count: number;
    payment_type: IPaymentType;
    pending_bids: IBid[];
    price: number;
    receipt_enabled: boolean;
    is_hidden: boolean;
    status: OrderClientStatus;
    type: string;
}

export interface IReason {
    id: number;
    body: string;
}

export interface ICancelReason {
    comment: string;
    reason_ids: number[];
}

export type IRecPriceRequest = (
    from_city_id: number | string,
    to_city_id: number | string,
    passenger_quantity: number | string,
) => Promise<IRecPrice | unknown>;

export interface IRecPrice {
    pool_price: number;
    pool_price_description: string;
    private_price: number;
    private_price_description: string;
}

export interface IErrorAuth {
    slug: string;
    text: string;
}

export interface IErrorResponse {
    meta: {
        code: number;
        details: [
            {
                fields: string[];
                message: string;
            },
        ];
        message: string;
        reason: string;
    };
}

export interface ICloudflareResponse {
    colo: string;
    fl: string;
    gateway: string;
    h: string;
    http: string;
    ip: string;
    kex: string;
    loc: string;
    rbi: string;
    sliver: string;
    sni: string;
    tls: string;
    ts: string;
    uag: string;
    visit_scheme: string;
    warp: string;
}

export interface IPopularRoute {
    city_from: IAutocompleteCity;
    city_to: IAutocompleteCity;
    price: {
        pool_price: number;
        pool_price_description: string;
        private_price: number;
        private_price_description: string;
    };
}

export interface ISearchItemAutocomplete {
    readonly value?: IAddress;
    readonly label?: string;
    readonly address?: string;
    readonly data: IAutocompleteAddress;
}

export interface IAutofilledAddress {
    fromCity: ISearchItemAutocomplete;
    toCity: ISearchItemAutocomplete;
}

export interface IPhoneToken {
    phone: string;
    token: string;
}

export interface IRoundTripInfo {
    trip_type: 'one_way' | 'round_trip';
    return_time: number | null;
    is_round_trip: boolean;
}
