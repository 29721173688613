import { Heading as HeadingNova, IHeadingProps } from '@indriver/nova';
import { FC } from 'react';
import { ITranslate } from 'entities/ITranslate';
import { useTranslation } from 'next-i18next';

export interface IExtendedHeadingProps {
    text?: ITranslate | string;
    tParams?: Record<string, unknown>;
}

const Heading: FC<IHeadingProps & IExtendedHeadingProps> = ({ text, tParams, ...props }) => {
    const { t } = useTranslation();
    return <HeadingNova {...props}>{text ? t(text, tParams) : props.children}</HeadingNova>;
};

export default Heading;
