// import { apiInstanceClient } from 'shared/lib/api-instance-client';
import {
    IAutocompleteAddress,
    IAutocompleteCity,
    ICancelReason,
    IOrder,
    IOrderForm,
    IPopularRoute,
    IReason,
    IRecPriceRequest,
} from 'common/api/models';
import { apiInstance } from 'common/api/base';

export interface ICitiesResponse {
    data: IAutocompleteCity[];
}

export interface ICarpoolFormParams {
    city_from_id: number;
    city_to_id: number;
    seats_count: number;
    departure_time: number;
}

export const getCities = async (search: string) => {
    const response = await apiInstance.get('/web/v1/cities', {
        params: { search: search },
    });
    return response.data;
    // return [
    //     {
    //         id: 13182,
    //         country_id: 69,
    //         name: 'Mumbwa',
    //         sub_name: 'Central Province, Zambia',
    //         timezone: 'Africa/Lusaka',
    //         currency_code: 'ZMW',
    //         payment_types: [
    //             {
    //                 id: 4181,
    //                 info: 'Cash',
    //                 method: 'cash',
    //                 provider: '',
    //                 type: '',
    //                 description: 'Cash',
    //                 description_short: 'Cash',
    //                 icon_url: 'http://indriver.com/images/payment_list/cash.png',
    //             },
    //         ],
    //     },
    //     {
    //         id: 13183,
    //         country_id: 8,
    //         name: 'Mumias',
    //         sub_name: 'Western, Kenya',
    //         timezone: 'Africa/Nairobi',
    //         currency_code: 'KES',
    //         payment_types: [
    //             {
    //                 id: 4181,
    //                 info: 'Cash',
    //                 method: 'cash',
    //                 provider: '',
    //                 type: '',
    //                 description: 'Cash',
    //                 description_short: 'Cash',
    //                 icon_url: 'http://indriver.com/images/payment_list/cash.png',
    //             },
    //         ],
    //     },
    //     {
    //         id: 5243,
    //         country_id: 14,
    //         name: 'Mumbai',
    //         sub_name: 'Maharashtra, India',
    //         timezone: 'Asia/Kolkata',
    //         currency_code: 'INR',
    //         payment_types: [
    //             {
    //                 id: 52,
    //                 info: 'QR-payment',
    //                 method: 'online_bank',
    //                 provider: 'QR-payment_India',
    //                 type: '',
    //                 description: 'QR-payment',
    //                 description_short: 'QR-payment',
    //                 icon_url:
    //                     'https://file-storage-front.eu-east-1.indriverapp.com/api/v1/files/8a1a1ece-f095-4110-a3bf-f10bad30ba69',
    //             },
    //             {
    //                 id: 4181,
    //                 info: 'Cash',
    //                 method: 'cash',
    //                 provider: '',
    //                 type: '',
    //                 description: 'Cash',
    //                 description_short: 'Cash',
    //                 icon_url: 'http://indriver.com/images/payment_list/cash.png',
    //             },
    //         ],
    //     },
    // ];
};

export const getAutocompleteAddressFrom = async (search: string, lat: number | null, lon: number | null) => {
    const response = await apiInstance.get<IAutocompleteAddress[]>('/web/v1/autocomplete/from_address', {
        params: { search, lat, lon },
        // headers: { 'X-City-Id': 123 },
    });
    return response.data;
};
export const getAutocompleteAddressTo = async (
    search: string,
    lat: number | null,
    lon: number | null,
    // city?: number,
) => {
    const response = await apiInstance.get<IAutocompleteAddress[]>('/web/v1/autocomplete/to_address', {
        params: { search, lat, lon },
        headers: { 'X-City-Id': null },
    });
    return response.data;
};

export const getCitiesByIds = async (city_ids: number[]): Promise<Record<string, IAutocompleteCity>> => {
    const response = await apiInstance.get('/web/v1/info/cities', {
        params: { ids: city_ids.toString() },
    });
    return response.data;
};

export const getCancelReason = async (order_id: string): Promise<IReason[]> => {
    const response = await apiInstance.get(`/api/v2/cancel_reasons/passenger/${order_id}`);
    return response.data;
};

export const getPriceByCity = async (city_from_id: number | string, city_to_id?: number | string | null) => {
    const response = await apiInstance.get('/web/v1/prices', {
        params: { city_from_id, city_to_id },
    });
    return response.data;
};

export const getRecommendedPriceByCity: IRecPriceRequest = async (from_city_id, to_city_id, passenger_quantity) => {
    const response = await apiInstance.get('/web/v1/passenger/recommended_price', {
        params: { from_city_id, to_city_id, passenger_quantity },
    });
    return response.data;
};

export const createOrder = async (data: IOrderForm) => {
    const response = await apiInstance.post('/api/v4/orders/passenger', data, {
        headers: {
            'X-City-Id': data.city_from_id,
        },
    });
    return response.data;
};

export const getOrder = async () => {
    const response = await apiInstance.get<IOrder>('/api/v4/orders/passenger');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return response.data || response?.response?.data;
};

export const cancelOrder = async (orderId: string, values: ICancelReason) => {
    const response = await apiInstance.post(`/api/v4/orders/${orderId}/passenger/cancel`, values);
    return response.data;
};
export const finishOrder = async (orderId: string) => {
    const response = await apiInstance.post(`/api/v4/orders/${orderId}/passenger/finish`);
    return response.data;
};

export const acceptBid = async (bidId: string) => {
    const response = await apiInstance.post(`/api/v3/bids/${bidId}/passenger/accept`);
    return response.data;
};
export const watchBid = async (bidId: string) => {
    const response = await apiInstance.post(`/web/auth/v1/bids/${bidId}/passenger/watched`);
    return response.data;
};

export const rejectBid = async (bidId: string) => {
    const response = await apiInstance.post(`/api/v3/bids/${bidId}/passenger/reject`);
    return response.data;
};

export const getPopularRoutes = async (country_id: number) => {
    const response = await apiInstance.get<IPopularRoute[]>('/web/v1/popular/routes', {
        params: {
            limit: 6,
            country_id,
        },
    });
    return response.data;
};

export const getUserAddressByCoords = async (lat: number, lon: number) => {
    const response = await apiInstance.get<IAutocompleteAddress>('/web/v1/autocomplete/point_a', {
        params: { lat, lon },
    });
    return response.data;
};
