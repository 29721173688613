import { FC } from 'react';
import { ILogoProps } from 'shared/ui';

const LogoIntercityPt: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                preserveAspectRatio='xMinYMid meet'
                viewBox='0 0 742.2 140.5'>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
                <g>
                    <path
                        className='cls-1'
                        d='m0,70.2c0-19.5,0-29.3,2.7-37.1C7.7,18.9,18.9,7.7,33.1,2.7,41,0,50.7,0,70.2,0s29.3,0,37.1,2.7c14.3,4.9,25.5,16.2,30.4,30.4,2.7,7.8,2.7,17.6,2.7,37.1s0,29.3-2.7,37.1c-4.9,14.3-16.2,25.5-30.4,30.4-7.8,2.7-17.6,2.7-37.1,2.7s-29.3,0-37.1-2.7c-14.3-4.9-25.5-16.2-30.4-30.4-2.7-7.8-2.7-17.6-2.7-37.1'
                    />
                    <rect x='36.2' y='60.5' width='16.5' height='46.9' rx='2.7' ry='2.7' />
                    <rect x='36.2' y='33' width='16.5' height='16.1' rx='2.7' ry='2.7' />
                    <path
                        className='cls-2'
                        d='m77.1,91.3h-9c-1.6,0-2.7,1.1-2.7,2.7v10.7c0,1.6,1.1,2.6,2.7,2.7h9c51,0,51-74.4,0-74.4h-9c-1.6,0-2.7,1.1-2.7,2.7v10.7c0,1.6,1.1,2.7,2.7,2.7h9c28.9,0,28.9,42.2,0,42.2'
                    />
                </g>
                <g>
                    <path
                        className='cls-2'
                        d='m332.4,18.8c8.6,0,14.4,6.8,12.7,15.1h-19.5c.4,3.5,3.3,5.6,6.6,5.6,3,0,5.4-1.4,6.8-4.1l5.5,2.8c-2.2,4.3-6.9,7.2-12.2,7.2-8,0-13.5-6-13.5-13.3s5.6-13.3,13.5-13.3m6.5,10.6c-.6-2.7-3.6-4.7-6.5-4.7s-5.8,1.9-6.5,4.7h13Z'
                    />
                    <path d='m207.7,22.1l-.2-2.6h-6.1v25.2h6.5v-13c0-7.7,10.7-8.3,10.7-1.7v14.6h6.5v-15.2c0-11.3-12.4-12.7-17.5-7.3' />
                    <path d='m242,38.5h-5v-19.6h5c5.8,0,9.8,4,9.8,9.8s-4.1,9.8-9.8,9.8h0Zm0-25.8h-11.6v32h11.6c9.3,0,16.4-6.9,16.4-16s-7-16-16.4-16h0Z' />
                    <path d='m269.1,22.2l-.2-2.7h-6.1v25.2h6.5v-10.9c0-6.2,4.7-8.8,9.9-7.7v-6.7c-3.2-.8-7.8-.3-10.2,2.9' />
                    <polygon points='312 19.5 305.9 37.6 299.7 19.5 292.8 19.5 302 44.7 309.8 44.7 319 19.5 312 19.5' />
                    <g>
                        <rect x='189.9' y='19.5' width='6.5' height='25.2' />
                        <rect x='189.9' y='9.8' width='6.5' height='6.5' />
                    </g>
                    <g>
                        <rect x='282.8' y='19.5' width='6.5' height='25.2' />
                        <rect x='282.8' y='9.8' width='6.5' height='6.5' />
                    </g>
                </g>
                <g>
                    <path d='m228.5,106.7c-1.2,10-9.3,16.7-19.7,16.7s-19.7-7.8-19.7-23.1,8-22.1,20.1-22.1,18.8,6.4,19.5,16.5h-11.4c-.6-4.5-3.8-7.1-8.1-7.1s-8.7,3.1-8.7,12.4,3.9,13.9,8.4,13.9,7.9-2.5,8.4-7.2h11.4Z' />
                    <path d='m232.4,74.1v-10.6h11.4v10.6h-11.4Zm0,48.1v-43h11.4v43h-11.4Z' />
                    <path d='m278,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z' />
                    <path d='m320.6,122.2c-.3-1-.6-2.5-.7-3.8-2.7,3-7.2,5-13,5-9.8,0-14.3-4.8-14.3-11.8,0-12.4,8.3-13.8,19.7-15.4,5.7-.8,7.2-1.9,7.2-4.9s-2.8-4.5-7.2-4.5-7.2,2.5-7.8,6.4h-10.6c.2-8.9,5.1-15.1,18.9-15.1s18.3,6.1,18.3,16.9v27.2h-10.6Zm-.8-21c-1.1,1.1-3.1,1.7-7.3,2.5-6.5,1.2-8.6,3.1-8.6,6.7s1.9,4.8,5.5,4.8c5.9,0,10.2-4.3,10.3-9.3v-4.7Z' />
                    <path d='m365.9,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z' />
                    <path d='m392.2,103.7c.3,6.5,4,10.7,9.6,10.7s6.9-1.8,7.7-4.8h11.7c-2.6,8.8-9.7,13.8-18.9,13.8-14.3,0-21.5-7.9-21.5-23.4s7.6-21.8,20.8-21.8,20.2,8.6,20.2,25.6h-29.6Zm17.9-7.2c-.2-6.4-4.4-9.4-8.8-9.4s-8.3,3.5-8.7,9.4h17.6Z' />
                    <path d='m473.7,122.2c-.3-1-.6-2.5-.7-3.8-2.7,3-7.2,5-13,5-9.8,0-14.3-4.8-14.3-11.8,0-12.4,8.3-13.8,19.7-15.4,5.7-.8,7.2-1.9,7.2-4.9s-2.8-4.5-7.2-4.5-7.2,2.5-7.8,6.4h-10.6c.2-8.9,5.1-15.1,18.9-15.1s18.3,6.1,18.3,16.9v27.2h-10.6Zm-.8-21c-1.1,1.1-3.1,1.7-7.3,2.5-6.5,1.2-8.6,3.1-8.6,6.7s1.9,4.8,5.5,4.8c5.9,0,10.2-4.3,10.3-9.3v-4.7Z' />
                    <path d='m549,106.7c-1.2,10-9.3,16.7-19.7,16.7s-19.7-7.8-19.7-23.1,8-22.1,20.1-22.1,18.8,6.4,19.5,16.5h-11.4c-.6-4.5-3.8-7.1-8.1-7.1s-8.7,3.1-8.7,12.4,3.9,13.9,8.4,13.9,7.9-2.5,8.4-7.2h11.4Z' />
                    <path d='m552.7,74.1v-10.6h11.4v10.6h-11.4Zm0,48.1v-43h11.4v43h-11.4Z' />
                    <path d='m598.4,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z' />
                    <path d='m641.1,122.2c-.3-1-.6-2.5-.7-3.8-2.7,3-7.2,5-13,5-9.8,0-14.3-4.8-14.3-11.8,0-12.4,8.3-13.8,19.7-15.4,5.7-.8,7.2-1.9,7.2-4.9s-2.8-4.5-7.2-4.5-7.2,2.5-7.8,6.4h-10.6c.2-8.9,5.1-15.1,18.9-15.1s18.3,6.1,18.3,16.9v27.2h-10.6Zm-.8-21c-1.1,1.1-3.1,1.7-7.3,2.5-6.5,1.2-8.6,3.1-8.6,6.7s1.9,4.8,5.5,4.8c5.9,0,10.2-4.3,10.3-9.3v-4.7Z' />
                    <path d='m686.3,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z' />
                    <path d='m712.6,103.7c.3,6.5,4,10.7,9.6,10.7s6.9-1.8,7.7-4.8h11.7c-2.6,8.8-9.7,13.8-18.9,13.8-14.3,0-21.5-7.9-21.5-23.4s7.6-21.8,20.8-21.8,20.2,8.6,20.2,25.6h-29.6Zm17.9-7.2c-.2-6.4-4.4-9.4-8.8-9.4s-8.3,3.5-8.7,9.4h17.6Z' />
                </g>
            </svg>
        );
    }

    return (
        <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 742.2 140.5'>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
            <g>
                <path
                    className='cls-4'
                    d='m0,70.2C0,50.7,0,41,2.7,33.1,7.7,18.9,18.9,7.7,33.1,2.7,41,0,50.7,0,70.3,0s29.3,0,37.1,2.7c14.3,4.9,25.5,16.2,30.4,30.4,2.7,7.8,2.7,17.6,2.7,37.1s0,29.3-2.7,37.1c-4.9,14.3-16.2,25.5-30.4,30.4-7.8,2.7-17.6,2.7-37.1,2.7s-29.3,0-37.1-2.7c-14.3-4.9-25.5-16.2-30.4-30.4C0,99.5,0,89.8,0,70.2'
                />
                <rect x='36.2' y='60.5' width='16.5' height='46.9' rx='2.7' ry='2.7' />
                <rect x='36.2' y='33' width='16.5' height='16.1' rx='2.7' ry='2.7' />
                <path
                    className='cls-3'
                    d='m77.1,91.3h-9c-1.6,0-2.7,1.1-2.7,2.7v10.7c0,1.6,1.1,2.6,2.7,2.7h9c51,0,51-74.4,0-74.4h-9c-1.6,0-2.7,1.1-2.7,2.7v10.7c0,1.6,1.1,2.7,2.7,2.7h9c28.9,0,28.9,42.2,0,42.2'
                />
            </g>
            <g>
                <path
                    className='cls-2'
                    d='m332.4,18.8c8.6,0,14.4,6.8,12.7,15.1h-19.5c.4,3.5,3.3,5.6,6.6,5.6,3,0,5.4-1.4,6.8-4.1l5.5,2.8c-2.2,4.3-6.9,7.2-12.2,7.2-8,0-13.5-6-13.5-13.3s5.6-13.3,13.5-13.3m6.5,10.6c-.6-2.7-3.6-4.7-6.5-4.7s-5.8,1.9-6.5,4.7h13Z'
                />
                <path
                    className='cls-1'
                    d='m207.7,22.1l-.2-2.6h-6.1v25.2h6.5v-13c0-7.7,10.7-8.3,10.7-1.7v14.6h6.5v-15.2c0-11.3-12.4-12.7-17.5-7.3'
                />
                <path
                    className='cls-1'
                    d='m242,38.5h-5v-19.6h5c5.8,0,9.8,4,9.8,9.8s-4.1,9.8-9.8,9.8h0Zm0-25.8h-11.6v32h11.6c9.3,0,16.4-6.9,16.4-16s-7-16-16.4-16h0Z'
                />
                <path
                    className='cls-1'
                    d='m269.1,22.2l-.2-2.7h-6.1v25.2h6.5v-10.9c0-6.2,4.7-8.8,9.9-7.7v-6.7c-3.2-.8-7.8-.3-10.2,2.9'
                />
                <polygon
                    className='cls-1'
                    points='312 19.5 305.9 37.6 299.7 19.5 292.8 19.5 302 44.7 309.8 44.7 319 19.5 312 19.5'
                />
                <g>
                    <rect className='cls-1' x='189.9' y='19.5' width='6.5' height='25.2' />
                    <rect className='cls-1' x='189.9' y='9.8' width='6.5' height='6.5' />
                </g>
                <g>
                    <rect className='cls-1' x='282.8' y='19.5' width='6.5' height='25.2' />
                    <rect className='cls-1' x='282.8' y='9.8' width='6.5' height='6.5' />
                </g>
            </g>
            <g>
                <path
                    className='cls-1'
                    d='m228.5,106.7c-1.2,10-9.3,16.7-19.7,16.7s-19.7-7.8-19.7-23.1,8-22.1,20.1-22.1,18.8,6.4,19.5,16.5h-11.4c-.6-4.5-3.8-7.1-8.1-7.1s-8.7,3.1-8.7,12.4,3.9,13.9,8.4,13.9,7.9-2.5,8.4-7.2h11.4Z'
                />
                <path className='cls-1' d='m232.4,74.1v-10.6h11.4v10.6h-11.4Zm0,48.1v-43h11.4v43h-11.4Z' />
                <path
                    className='cls-1'
                    d='m278,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z'
                />
                <path
                    className='cls-1'
                    d='m320.6,122.2c-.3-1-.6-2.5-.7-3.8-2.7,3-7.2,5-13,5-9.8,0-14.3-4.8-14.3-11.8,0-12.4,8.3-13.8,19.7-15.4,5.7-.8,7.2-1.9,7.2-4.9s-2.8-4.5-7.2-4.5-7.2,2.5-7.8,6.4h-10.6c.2-8.9,5.1-15.1,18.9-15.1s18.3,6.1,18.3,16.9v27.2h-10.6Zm-.8-21c-1.1,1.1-3.1,1.7-7.3,2.5-6.5,1.2-8.6,3.1-8.6,6.7s1.9,4.8,5.5,4.8c5.9,0,10.2-4.3,10.3-9.3v-4.7Z'
                />
                <path
                    className='cls-1'
                    d='m365.9,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z'
                />
                <path
                    className='cls-1'
                    d='m392.2,103.7c.3,6.5,4,10.7,9.6,10.7s6.9-1.8,7.7-4.8h11.7c-2.6,8.8-9.7,13.8-18.9,13.8-14.3,0-21.5-7.9-21.5-23.4s7.6-21.8,20.8-21.8,20.2,8.6,20.2,25.6h-29.6Zm17.9-7.2c-.2-6.4-4.4-9.4-8.8-9.4s-8.3,3.5-8.7,9.4h17.6Z'
                />
                <path
                    className='cls-1'
                    d='m473.7,122.2c-.3-1-.6-2.5-.7-3.8-2.7,3-7.2,5-13,5-9.8,0-14.3-4.8-14.3-11.8,0-12.4,8.3-13.8,19.7-15.4,5.7-.8,7.2-1.9,7.2-4.9s-2.8-4.5-7.2-4.5-7.2,2.5-7.8,6.4h-10.6c.2-8.9,5.1-15.1,18.9-15.1s18.3,6.1,18.3,16.9v27.2h-10.6Zm-.8-21c-1.1,1.1-3.1,1.7-7.3,2.5-6.5,1.2-8.6,3.1-8.6,6.7s1.9,4.8,5.5,4.8c5.9,0,10.2-4.3,10.3-9.3v-4.7Z'
                />
                <path
                    className='cls-1'
                    d='m549,106.7c-1.2,10-9.3,16.7-19.7,16.7s-19.7-7.8-19.7-23.1,8-22.1,20.1-22.1,18.8,6.4,19.5,16.5h-11.4c-.6-4.5-3.8-7.1-8.1-7.1s-8.7,3.1-8.7,12.4,3.9,13.9,8.4,13.9,7.9-2.5,8.4-7.2h11.4Z'
                />
                <path className='cls-1' d='m552.7,74.1v-10.6h11.4v10.6h-11.4Zm0,48.1v-43h11.4v43h-11.4Z' />
                <path
                    className='cls-1'
                    d='m598.4,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z'
                />
                <path
                    className='cls-1'
                    d='m641.1,122.2c-.3-1-.6-2.5-.7-3.8-2.7,3-7.2,5-13,5-9.8,0-14.3-4.8-14.3-11.8,0-12.4,8.3-13.8,19.7-15.4,5.7-.8,7.2-1.9,7.2-4.9s-2.8-4.5-7.2-4.5-7.2,2.5-7.8,6.4h-10.6c.2-8.9,5.1-15.1,18.9-15.1s18.3,6.1,18.3,16.9v27.2h-10.6Zm-.8-21c-1.1,1.1-3.1,1.7-7.3,2.5-6.5,1.2-8.6,3.1-8.6,6.7s1.9,4.8,5.5,4.8c5.9,0,10.2-4.3,10.3-9.3v-4.7Z'
                />
                <path
                    className='cls-1'
                    d='m686.3,122.2v-5.2c-2.8,4.4-6.8,6.4-12.1,6.4-10.8,0-18.6-8.6-18.6-23.1s6.6-22.2,17.6-22.2,9.5,2.2,12.8,6.7v-21.3h11.2v58.7h-10.9Zm.2-20.4c0-9.3-3.2-14-9.5-14s-9.8,4.5-9.8,13.3,4,13,9.7,13,9.6-5.1,9.6-12.3Z'
                />
                <path
                    className='cls-1'
                    d='m712.6,103.7c.3,6.5,4,10.7,9.6,10.7s6.9-1.8,7.7-4.8h11.7c-2.6,8.8-9.7,13.8-18.9,13.8-14.3,0-21.5-7.9-21.5-23.4s7.6-21.8,20.8-21.8,20.2,8.6,20.2,25.6h-29.6Zm17.9-7.2c-.2-6.4-4.4-9.4-8.8-9.4s-8.3,3.5-8.7,9.4h17.6Z'
                />
            </g>
        </svg>
    );
};

export default LogoIntercityPt;
