export { default as LogoIntercityRu } from './logo-intercity-ru';
export { default as LogoIntercityEn } from './logo-intercity-en';
export { default as LogoIntercityEnOutstation } from './logo-intercity-en-outstation';
export { default as LogoIntercityEs } from './logo-intercity-es';
export { default as LogoIntercityHi } from './logo-intercity-hi';
export { default as LogoIntercityAr } from './logo-intercity-ar';
export { default as LogoIntercityFr } from './logo-intercity-fr';
export { default as LogoIntercityUr } from './logo-intercity-ur';
export { default as LogoIntercityPt } from './logo-intercity-pt';
export { default as LogoIntercityId } from './logo-intercity-id';
export { default as LogoIntercityTh } from './logo-intercity-th';
export { default as LogoIntercityNe } from './logo-intercity-ne';

export interface ILogoProps {
    light?: boolean;
}
