import { Text as TextNova, ITextProps } from '@indriver/nova';
import { FC } from 'react';
import { ITranslate } from 'entities/ITranslate';
import { useTranslation } from 'next-i18next';

interface IExtendedHeadingProps {
    text?: ITranslate | string;
    tParams?: Record<string, unknown>;
}

const Text: FC<ITextProps & IExtendedHeadingProps> = ({ text, tParams, ...props }) => {
    const { t } = useTranslation();
    return <TextNova {...props}>{text ? t(text, tParams) : props.children}</TextNova>;
};

export default Text;
