import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityTh: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg id='_Слой_2' data-name='Слой 2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 545.77 138.35'>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
                <g id='Layer_1' data-name='Layer 1'>
                    <g>
                        <g>
                            <path
                                className='cls-1'
                                d='M0,69.17c0-19.21,0-28.81,2.68-36.54C7.55,18.59,18.59,7.55,32.64,2.68,40.37,0,49.97,0,69.17,0s28.81,0,36.54,2.68c14.05,4.87,25.09,15.91,29.96,29.96,2.68,7.73,2.68,17.33,2.68,36.54s0,28.81-2.68,36.54c-4.87,14.05-15.91,25.09-29.96,29.96-7.73,2.68-17.33,2.68-36.54,2.68s-28.81,0-36.54-2.68c-14.05-4.87-25.09-15.91-29.96-29.96-2.68-7.73-2.68-17.33-2.68-36.54'
                            />
                            <rect x='35.68' y='59.59' width='16.29' height='46.18' rx='2.62' ry='2.62' />
                            <rect x='35.68' y='32.53' width='16.29' height='15.85' rx='2.62' ry='2.62' />
                            <path
                                className='cls-2'
                                d='M75.89,89.95h-8.81c-1.57,0-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.61,2.62,2.62l8.81,.06c50.22,0,50.22-73.29,0-73.29l-8.81,.06c-1.57,.01-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.62,2.62,2.62h8.81c28.41,0,28.41,41.55,0,41.55'
                            />
                        </g>
                        <g>
                            <path
                                className='cls-2'
                                d='M327.3,18.51c8.47,0,14.15,6.65,12.55,14.82h-19.16c.42,3.46,3.29,5.56,6.5,5.55,2.93,0,5.35-1.33,6.65-4.02l5.44,2.78c-2.18,4.27-6.77,7.08-11.98,7.08-7.83-.04-13.27-5.87-13.27-13.11s5.49-13.06,13.27-13.11m6.39,10.43c-.63-2.65-3.5-4.6-6.41-4.6s-5.69,1.86-6.41,4.6h12.82Z'
                            />
                            <path d='M204.55,21.78l-.19-2.56h-5.98v24.79h6.44v-12.75c0-7.6,10.5-8.18,10.5-1.67v14.42h6.44v-15c0-11.11-12.25-12.47-17.23-7.23' />
                            <path d='M238.3,37.88h-4.96V18.59h4.96c5.68,0,9.69,3.97,9.69,9.65s-4.01,9.65-9.69,9.65h0Zm0-25.42h-11.4v31.55h11.4c9.19,0,16.13-6.76,16.13-15.78s-6.94-15.78-16.13-15.78h0Z' />
                            <path d='M264.97,21.91l-.19-2.69h-5.98v24.79h6.44v-10.77c0-6.08,4.63-8.7,9.74-7.57v-6.62c-3.11-.78-7.69-.3-10.02,2.87' />
                            <polygon points='307.27 19.22 301.18 37.02 295.13 19.22 288.28 19.22 297.34 44.01 305.05 44.01 314.12 19.22 307.27 19.22' />
                            <g>
                                <rect x='186.95' y='19.22' width='6.45' height='24.79' />
                                <rect x='186.95' y='9.62' width='6.45' height='6.45' />
                            </g>
                            <g>
                                <rect x='278.5' y='19.22' width='6.44' height='24.79' />
                                <rect x='278.5' y='9.62' width='6.45' height='6.45' />
                            </g>
                        </g>
                        <g>
                            <path d='M193.18,117.37c-3.34-2.96-5-7.1-5-12.43v-2.74c0-1.94,.39-3.64,1.17-5.12,.78-1.48,1.84-3.11,3.19-4.88,.86-1.13,1.49-2.03,1.9-2.7,.4-.67,.61-1.33,.61-1.98,0-.81-.19-1.34-.56-1.61-.38-.27-1.05-.4-2.02-.4h-5.25l.89-8.07h11.3c4.46,0,6.7,2.07,6.7,6.21,0,1.61-.3,3.08-.89,4.4-.59,1.32-1.45,2.92-2.58,4.8-1.18,1.78-2.07,3.35-2.66,4.72-.59,1.37-.89,2.89-.89,4.56v3.47c0,2.53,.74,4.45,2.22,5.77,1.48,1.32,3.64,1.98,6.5,1.98,5.92,0,8.88-2.69,8.88-8.07v-27.84h10.89v27.35c0,5.27-1.76,9.43-5.28,12.47-3.52,3.04-8.35,4.56-14.48,4.56s-11.27-1.48-14.61-4.44Z' />
                            <path d='M207.94,64.28h-6.29v-7.42h13.64v7.18h17.27l-.4,7.42h-24.21v-7.18Z' />
                            <path d='M253.38,94.21c0-3.12-.61-5.42-1.82-6.9-1.21-1.48-3.43-2.22-6.66-2.22-1.72,0-3.58,.19-5.57,.56-1.99,.38-3.79,.89-5.41,1.53v-7.99c1.67-.75,3.68-1.37,6.05-1.86,2.37-.48,4.71-.73,7.02-.73,6.08,0,10.48,1.29,13.19,3.87,2.72,2.58,4.07,6.64,4.07,12.18v28.32h-10.89v-26.79Z' />
                            <path d='M282.21,116.73l-.48,4.28h-10.01v-43.57h10.89v31.39c1.18,1.29,2.76,2.37,4.72,3.23,1.96,.86,3.94,1.29,5.93,1.29,2.63,0,4.6-.65,5.89-1.94,1.29-1.29,1.94-3.39,1.94-6.29v-27.68h10.89v28.32c0,5-1.35,8.93-4.03,11.78-2.69,2.85-6.38,4.28-11.05,4.28-5.6,0-10.49-1.7-14.69-5.08Z' />
                            <path d='M334.04,121.45c-1.53-.24-2.76-.52-3.67-.85l-6.13-16.38h-5.97l.48-7.75h12.35l6.37,17.03c3.44,0,6.05-1.18,7.83-3.55,1.77-2.37,2.66-6.08,2.66-11.14,0-3.55-.46-6.31-1.37-8.27-.91-1.96-2.42-3.36-4.52-4.2-2.1-.83-5.03-1.25-8.8-1.25-2.21,0-4.53,.18-6.98,.52-2.45,.35-4.75,.87-6.9,1.57v-8.07c5.27-1.67,10.84-2.5,16.7-2.5,7.75,0,13.38,1.86,16.9,5.57,3.52,3.71,5.29,9.25,5.29,16.62,0,4.84-.92,9-2.74,12.47-1.83,3.47-4.28,6.09-7.34,7.87-3.07,1.78-6.43,2.66-10.09,2.66-1.18,0-2.54-.12-4.07-.36Z' />
                            <path d='M337.31,58.63h8.88v5.41h20.09l-.4,7.42h-28.56v-12.83Z' />
                            <path d='M375.4,120.6l-12.27-29.86h10.57l9.6,23.32c3.98,0,6.88-1.34,8.71-4.03,1.83-2.69,2.74-6.75,2.74-12.18,0-3.28-.31-5.82-.93-7.63-.62-1.8-1.63-3.09-3.03-3.87-1.4-.78-3.34-1.17-5.81-1.17h-3.31v-7.75h4.84c6.35,0,11.03,1.67,14.04,5,3.01,3.34,4.52,8.47,4.52,15.41,0,8.18-1.86,14.22-5.57,18.12-3.71,3.9-8.77,5.85-15.17,5.85-3.44,0-6.43-.4-8.96-1.21Z' />
                            <path d='M412.26,77.43h10.89v13.23h10.81c2.69,0,4.59-.59,5.69-1.78,1.1-1.18,1.65-3.04,1.65-5.57v-5.89h10.89v5.49c0,6.08-2.07,9.95-6.21,11.62,2.15,.86,3.67,2.14,4.56,3.83,.89,1.69,1.33,3.83,1.33,6.42v16.22h-10.89v-15.01c0-2.74-.52-4.65-1.57-5.73-1.05-1.08-2.95-1.61-5.69-1.61h-10.57v22.35h-10.89v-43.57Z' />
                            <path d='M459.34,119.23v-8.31c3.66,1.61,7.42,2.42,11.3,2.42,5.11,0,8.74-1.06,10.89-3.19,2.15-2.12,3.23-5.77,3.23-10.93s-1.08-8.81-3.23-10.93c-2.15-2.12-5.78-3.19-10.89-3.19-3.87,0-7.64,.81-11.3,2.42v-8.31c4.03-1.72,8.66-2.58,13.88-2.58,7.32,0,12.79,1.91,16.42,5.73,3.63,3.82,5.45,9.44,5.45,16.87s-1.82,13.05-5.45,16.86c-3.63,3.82-9.11,5.73-16.42,5.73-5.22,0-9.84-.86-13.88-2.58Z' />
                            <path d='M471.17,58.63h8.88v5.41h20.09l-.4,7.42h-28.56v-12.83Z' />
                            <path d='M506.03,116.44c-3.31-3.58-4.96-9.02-4.96-16.34s1.94-13.26,5.81-17.35c3.87-4.09,9.52-6.13,16.95-6.13,4.95,0,9.08,.83,12.39,2.5,3.31,1.67,5.73,3.87,7.26,6.62,1.53,2.74,2.3,5.73,2.3,8.96v26.31h-10.89v-25.58c0-3.28-.85-5.82-2.54-7.63-1.69-1.8-4.59-2.7-8.67-2.7-8.18,0-12.27,5-12.27,15.01,0,4.73,.82,8.12,2.46,10.17,1.64,2.04,4.1,3.07,7.38,3.07,2.47,0,4.33-.21,5.57-.65v7.99c-1.99,.75-4.49,1.13-7.5,1.13-5.54,0-9.97-1.79-13.27-5.37Z' />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
    return (
        <svg id='_Слой_2' data-name='Слой 2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 545.77 138.35'>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
            <g id='Layer_1' data-name='Layer 1'>
                <g>
                    <g>
                        <path
                            className='cls-4'
                            d='M0,69.17c0-19.21,0-28.81,2.68-36.54C7.55,18.59,18.59,7.55,32.64,2.68,40.37,0,49.97,0,69.17,0s28.81,0,36.54,2.68c14.05,4.87,25.09,15.91,29.96,29.96,2.68,7.73,2.68,17.33,2.68,36.54s0,28.81-2.68,36.54c-4.87,14.05-15.91,25.09-29.96,29.96-7.73,2.68-17.33,2.68-36.54,2.68s-28.81,0-36.54-2.68c-14.05-4.87-25.09-15.91-29.96-29.96-2.68-7.73-2.68-17.33-2.68-36.54'
                        />
                        <rect x='35.68' y='59.59' width='16.29' height='46.18' rx='2.62' ry='2.62' />
                        <rect x='35.68' y='32.53' width='16.29' height='15.85' rx='2.62' ry='2.62' />
                        <path
                            className='cls-3'
                            d='M75.89,89.95h-8.81c-1.57,0-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.61,2.62,2.62l8.81,.06c50.22,0,50.22-73.29,0-73.29l-8.81,.06c-1.57,.01-2.62,1.05-2.62,2.62v10.57c0,1.57,1.05,2.62,2.62,2.62h8.81c28.41,0,28.41,41.55,0,41.55'
                        />
                    </g>
                    <g>
                        <path
                            className='cls-2'
                            d='M327.3,18.51c8.47,0,14.15,6.65,12.55,14.82h-19.16c.42,3.46,3.29,5.56,6.5,5.55,2.93,0,5.35-1.33,6.65-4.02l5.44,2.78c-2.18,4.27-6.77,7.08-11.98,7.08-7.83-.04-13.27-5.87-13.27-13.11s5.49-13.06,13.27-13.11m6.39,10.43c-.63-2.65-3.5-4.6-6.41-4.6s-5.69,1.86-6.41,4.6h12.82Z'
                        />
                        <path
                            className='cls-1'
                            d='M204.55,21.78l-.19-2.56h-5.98v24.79h6.44v-12.75c0-7.6,10.5-8.18,10.5-1.67v14.42h6.44v-15c0-11.11-12.25-12.47-17.23-7.23'
                        />
                        <path
                            className='cls-1'
                            d='M238.3,37.88h-4.96V18.59h4.96c5.68,0,9.69,3.97,9.69,9.65s-4.01,9.65-9.69,9.65h0Zm0-25.42h-11.4v31.55h11.4c9.19,0,16.13-6.76,16.13-15.78s-6.94-15.78-16.13-15.78h0Z'
                        />
                        <path
                            className='cls-1'
                            d='M264.97,21.91l-.19-2.69h-5.98v24.79h6.44v-10.77c0-6.08,4.63-8.7,9.74-7.57v-6.62c-3.11-.78-7.69-.3-10.02,2.87'
                        />
                        <polygon
                            className='cls-1'
                            points='307.27 19.22 301.18 37.02 295.13 19.22 288.28 19.22 297.34 44.01 305.05 44.01 314.12 19.22 307.27 19.22'
                        />
                        <g>
                            <rect className='cls-1' x='186.95' y='19.22' width='6.45' height='24.79' />
                            <rect className='cls-1' x='186.95' y='9.62' width='6.45' height='6.45' />
                        </g>
                        <g>
                            <rect className='cls-1' x='278.5' y='19.22' width='6.44' height='24.79' />
                            <rect className='cls-1' x='278.5' y='9.62' width='6.45' height='6.45' />
                        </g>
                    </g>
                    <g>
                        <path
                            className='cls-1'
                            d='M193.18,117.37c-3.34-2.96-5-7.1-5-12.43v-2.74c0-1.94,.39-3.64,1.17-5.12,.78-1.48,1.84-3.11,3.19-4.88,.86-1.13,1.49-2.03,1.9-2.7,.4-.67,.61-1.33,.61-1.98,0-.81-.19-1.34-.56-1.61-.38-.27-1.05-.4-2.02-.4h-5.25l.89-8.07h11.3c4.46,0,6.7,2.07,6.7,6.21,0,1.61-.3,3.08-.89,4.4-.59,1.32-1.45,2.92-2.58,4.8-1.18,1.78-2.07,3.35-2.66,4.72-.59,1.37-.89,2.89-.89,4.56v3.47c0,2.53,.74,4.45,2.22,5.77,1.48,1.32,3.64,1.98,6.5,1.98,5.92,0,8.88-2.69,8.88-8.07v-27.84h10.89v27.35c0,5.27-1.76,9.43-5.28,12.47-3.52,3.04-8.35,4.56-14.48,4.56s-11.27-1.48-14.61-4.44Z'
                        />
                        <path className='cls-1' d='M207.94,64.28h-6.29v-7.42h13.64v7.18h17.27l-.4,7.42h-24.21v-7.18Z' />
                        <path
                            className='cls-1'
                            d='M253.38,94.21c0-3.12-.61-5.42-1.82-6.9-1.21-1.48-3.43-2.22-6.66-2.22-1.72,0-3.58,.19-5.57,.56-1.99,.38-3.79,.89-5.41,1.53v-7.99c1.67-.75,3.68-1.37,6.05-1.86,2.37-.48,4.71-.73,7.02-.73,6.08,0,10.48,1.29,13.19,3.87,2.72,2.58,4.07,6.64,4.07,12.18v28.32h-10.89v-26.79Z'
                        />
                        <path
                            className='cls-1'
                            d='M282.21,116.73l-.48,4.28h-10.01v-43.57h10.89v31.39c1.18,1.29,2.76,2.37,4.72,3.23,1.96,.86,3.94,1.29,5.93,1.29,2.63,0,4.6-.65,5.89-1.94,1.29-1.29,1.94-3.39,1.94-6.29v-27.68h10.89v28.32c0,5-1.35,8.93-4.03,11.78-2.69,2.85-6.38,4.28-11.05,4.28-5.6,0-10.49-1.7-14.69-5.08Z'
                        />
                        <path
                            className='cls-1'
                            d='M334.04,121.45c-1.53-.24-2.76-.52-3.67-.85l-6.13-16.38h-5.97l.48-7.75h12.35l6.37,17.03c3.44,0,6.05-1.18,7.83-3.55,1.77-2.37,2.66-6.08,2.66-11.14,0-3.55-.46-6.31-1.37-8.27-.91-1.96-2.42-3.36-4.52-4.2-2.1-.83-5.03-1.25-8.8-1.25-2.21,0-4.53,.18-6.98,.52-2.45,.35-4.75,.87-6.9,1.57v-8.07c5.27-1.67,10.84-2.5,16.7-2.5,7.75,0,13.38,1.86,16.9,5.57,3.52,3.71,5.29,9.25,5.29,16.62,0,4.84-.92,9-2.74,12.47-1.83,3.47-4.28,6.09-7.34,7.87-3.07,1.78-6.43,2.66-10.09,2.66-1.18,0-2.54-.12-4.07-.36Z'
                        />
                        <path className='cls-1' d='M337.31,58.63h8.88v5.41h20.09l-.4,7.42h-28.56v-12.83Z' />
                        <path
                            className='cls-1'
                            d='M375.4,120.6l-12.27-29.86h10.57l9.6,23.32c3.98,0,6.88-1.34,8.71-4.03,1.83-2.69,2.74-6.75,2.74-12.18,0-3.28-.31-5.82-.93-7.63-.62-1.8-1.63-3.09-3.03-3.87-1.4-.78-3.34-1.17-5.81-1.17h-3.31v-7.75h4.84c6.35,0,11.03,1.67,14.04,5,3.01,3.34,4.52,8.47,4.52,15.41,0,8.18-1.86,14.22-5.57,18.12-3.71,3.9-8.77,5.85-15.17,5.85-3.44,0-6.43-.4-8.96-1.21Z'
                        />
                        <path
                            className='cls-1'
                            d='M412.26,77.43h10.89v13.23h10.81c2.69,0,4.59-.59,5.69-1.78,1.1-1.18,1.65-3.04,1.65-5.57v-5.89h10.89v5.49c0,6.08-2.07,9.95-6.21,11.62,2.15,.86,3.67,2.14,4.56,3.83,.89,1.69,1.33,3.83,1.33,6.42v16.22h-10.89v-15.01c0-2.74-.52-4.65-1.57-5.73-1.05-1.08-2.95-1.61-5.69-1.61h-10.57v22.35h-10.89v-43.57Z'
                        />
                        <path
                            className='cls-1'
                            d='M459.34,119.23v-8.31c3.66,1.61,7.42,2.42,11.3,2.42,5.11,0,8.74-1.06,10.89-3.19,2.15-2.12,3.23-5.77,3.23-10.93s-1.08-8.81-3.23-10.93c-2.15-2.12-5.78-3.19-10.89-3.19-3.87,0-7.64,.81-11.3,2.42v-8.31c4.03-1.72,8.66-2.58,13.88-2.58,7.32,0,12.79,1.91,16.42,5.73,3.63,3.82,5.45,9.44,5.45,16.87s-1.82,13.05-5.45,16.86c-3.63,3.82-9.11,5.73-16.42,5.73-5.22,0-9.84-.86-13.88-2.58Z'
                        />
                        <path className='cls-1' d='M471.17,58.63h8.88v5.41h20.09l-.4,7.42h-28.56v-12.83Z' />
                        <path
                            className='cls-1'
                            d='M506.03,116.44c-3.31-3.58-4.96-9.02-4.96-16.34s1.94-13.26,5.81-17.35c3.87-4.09,9.52-6.13,16.95-6.13,4.95,0,9.08,.83,12.39,2.5,3.31,1.67,5.73,3.87,7.26,6.62,1.53,2.74,2.3,5.73,2.3,8.96v26.31h-10.89v-25.58c0-3.28-.85-5.82-2.54-7.63-1.69-1.8-4.59-2.7-8.67-2.7-8.18,0-12.27,5-12.27,15.01,0,4.73,.82,8.12,2.46,10.17,1.64,2.04,4.1,3.07,7.38,3.07,2.47,0,4.33-.21,5.57-.65v7.99c-1.99,.75-4.49,1.13-7.5,1.13-5.54,0-9.97-1.79-13.27-5.37Z'
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default LogoIntercityTh;
