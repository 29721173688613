import { Button as ButtonNova } from '@indriver/nova';
import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import { IButtonProps as IBaseButtonProps } from '@indriver/nova-core';
import { ITranslate } from 'entities/ITranslate';
import { useTranslation } from 'next-i18next';

export interface IButtonProps {
    text?: ITranslate | string;
    tParams?: Record<string, unknown>;
}

export type IExtendedButtonProps = IButtonProps & IBaseButtonProps;

const Button: ForwardRefExoticComponent<Omit<IExtendedButtonProps, 'ref'> & RefAttributes<HTMLButtonElement>> =
    forwardRef<HTMLButtonElement, IExtendedButtonProps>(({ text, tParams, ...props }, ref) => {
        const { t } = useTranslation();
        return (
            <ButtonNova {...props} ref={ref}>
                {text ? t(text, tParams) : props.children}
            </ButtonNova>
        );
    });

export default Button;
