import { useState } from 'react';
import useEventListener from './useEventListener';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

interface IWindowSize {
    width: number;
    height: number;
}

function useWindowSize(): IWindowSize {
    const [windowSize, setWindowSize] = useState<IWindowSize>({
        width: 0,
        height: 0,
    });

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEventListener('resize', handleSize);

    useIsomorphicLayoutEffect(() => {
        handleSize();
    }, []);

    return windowSize;
}

export default useWindowSize;
