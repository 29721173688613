import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityFr: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 580.7 138.5'>
                <defs>
                    <style jsx global>
                        {`
                            .cls-1 {
                                fill: #a7e92f;
                            }

                            .cls-2 {
                                fill-rule: evenodd;
                            }
                        `}
                    </style>
                </defs>
                <g>
                    <path
                        className='cls-1'
                        d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.3,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                    />
                    <rect x='35.7' y='59.7' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                    <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                    <path
                        className='cls-2'
                        d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                    />
                </g>
                <g>
                    <path
                        className='cls-2'
                        d='m327.7,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                    />
                    <path d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2' />
                    <path d='m238.6,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z' />
                    <path d='m265.3,21.9l-.2-2.7h-6v24.8h6.5v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9' />
                    <polygon points='307.6 19.2 301.5 37.1 295.5 19.2 288.6 19.2 297.7 44.1 305.4 44.1 314.5 19.2 307.6 19.2' />
                    <g>
                        <rect x='187.2' y='19.2' width='6.5' height='24.8' />
                        <rect x='187.2' y='9.6' width='6.5' height='6.5' />
                    </g>
                    <g>
                        <rect x='278.8' y='19.2' width='6.5' height='24.8' />
                        <rect x='278.8' y='9.6' width='6.5' height='6.5' />
                    </g>
                </g>
                <g>
                    <path d='m200.3,120.5l-15.2-42.4h12.5l8.9,28,9-28h11.8l-15.2,42.4h-11.7Z' />
                    <path d='m229.4,73v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                    <path d='m245.9,120.5v-57.9h11.2v57.9h-11.2Z' />
                    <path d='m262.5,120.5v-57.9h11.2v57.9h-11.2Z' />
                    <path d='m289.2,102.2c.3,6.4,4,10.5,9.5,10.5s6.8-1.8,7.6-4.7h11.5c-2.6,8.7-9.6,13.6-18.6,13.6-14.1,0-21.2-7.7-21.2-23.1s7.5-21.5,20.5-21.5,19.9,8.4,19.9,25.2h-29.2Zm17.6-7.1c-.2-6.3-4.3-9.3-8.7-9.3s-8.2,3.4-8.6,9.3h17.3Z' />
                    <path d='m352.7,102.2c.3,6.4,4,10.5,9.5,10.5s6.8-1.8,7.6-4.7h11.5c-2.6,8.7-9.6,13.6-18.6,13.6-14.1,0-21.2-7.7-21.2-23.1s7.5-21.5,20.5-21.5,19.9,8.4,19.9,25.2h-29.2Zm17.6-7.1c-.2-6.3-4.3-9.3-8.7-9.3s-8.2,3.4-8.6,9.3h17.3Z' />
                    <path d='m413,120.5v-25.7c0-6.5-1.9-8.4-7.2-8.4s-8.7,3.3-8.7,9.7v24.3h-11.2v-42.4h10.7v6.2c2.6-4.7,6.9-7.3,13.7-7.3s14,4.9,14,14.1v29.4h-11.3Z' />
                    <path d='m462.6,120.5l-15.2-42.4h12.5l8.9,28,9-28h11.8l-15.2,42.4h-11.7Z' />
                    <path d='m491.7,73v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                    <path d='m508.3,120.5v-57.9h11.2v57.9h-11.2Z' />
                    <path d='m524.8,120.5v-57.9h11.2v57.9h-11.2Z' />
                    <path d='m551.5,102.2c.3,6.4,4,10.5,9.5,10.5s6.8-1.8,7.6-4.7h11.5c-2.6,8.7-9.6,13.6-18.6,13.6-14.1,0-21.2-7.7-21.2-23.1s7.5-21.5,20.5-21.5,19.9,8.4,19.9,25.2h-29.2Zm17.6-7.1c-.2-6.3-4.3-9.3-8.7-9.3s-8.2,3.4-8.6,9.3h17.3Z' />
                </g>
            </svg>
        );
    }

    return (
        <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 580.7 138.5'>
            <defs>
                <style jsx global>
                    {`
                        .cls-1,
                        .cls-2 {
                            fill: #fff;
                        }

                        .cls-2,
                        .cls-3 {
                            fill-rule: evenodd;
                        }

                        .cls-4 {
                            fill: #a7e92f;
                        }
                    `}
                </style>
            </defs>
            <g>
                <path
                    className='cls-4'
                    d='m0,69.2C0,50,0,40.4,2.7,32.7,7.6,18.6,18.6,7.6,32.7,2.7,40.4,0,50,0,69.3,0s28.8,0,36.6,2.7c14.1,4.9,25.1,15.9,30,30,2.7,7.7,2.7,17.3,2.7,36.6s0,28.8-2.7,36.6c-4.9,14.1-15.9,25.1-30,30-7.7,2.7-17.3,2.7-36.6,2.7s-28.8,0-36.6-2.7c-14.1-4.9-25.1-15.9-30-30C0,98.1,0,88.5,0,69.2'
                />
                <rect x='35.7' y='59.7' width='16.3' height='46.2' rx='2.6' ry='2.6' />
                <rect x='35.7' y='32.6' width='16.3' height='15.9' rx='2.6' ry='2.6' />
                <path
                    className='cls-3'
                    d='m76,90h-8.8c-1.6,0-2.6,1-2.6,2.6v10.6c0,1.6,1,2.6,2.6,2.6h8.8c50.3,0,50.3-73.3,0-73.3h-8.8c-1.6,0-2.6,1.1-2.6,2.7v10.6c0,1.6,1,2.6,2.6,2.6h8.8c28.4,0,28.4,41.6,0,41.6'
                />
            </g>
            <g>
                <path
                    className='cls-2'
                    d='m327.7,18.5c8.5,0,14.2,6.7,12.6,14.8h-19.2c.4,3.5,3.3,5.6,6.5,5.6,2.9,0,5.4-1.3,6.7-4l5.4,2.8c-2.2,4.3-6.8,7.1-12,7.1-7.8,0-13.3-5.9-13.3-13.1s5.5-13.1,13.3-13.1m6.4,10.4c-.6-2.7-3.5-4.6-6.4-4.6s-5.7,1.9-6.4,4.6h12.8Z'
                />
                <path
                    className='cls-1'
                    d='m204.8,21.8l-.2-2.6h-6v24.8h6.5v-12.8c0-7.6,10.5-8.2,10.5-1.7v14.4h6.5v-15c0-11.1-12.3-12.5-17.2-7.2'
                />
                <path
                    className='cls-1'
                    d='m238.6,37.9h-5v-19.3h5c5.7,0,9.7,4,9.7,9.7s-4,9.7-9.7,9.7h0Zm0-25.4h-11.4v31.6h11.4c9.2,0,16.2-6.8,16.2-15.8s-6.9-15.8-16.2-15.8h0Z'
                />
                <path
                    className='cls-1'
                    d='m265.3,21.9l-.2-2.7h-6v24.8h6.5v-10.8c0-6.1,4.6-8.7,9.8-7.6v-6.6c-3.1-.8-7.7-.3-10,2.9'
                />
                <polygon
                    className='cls-1'
                    points='307.6 19.2 301.5 37.1 295.5 19.2 288.6 19.2 297.7 44.1 305.4 44.1 314.5 19.2 307.6 19.2'
                />
                <g>
                    <rect className='cls-1' x='187.2' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='187.2' y='9.6' width='6.5' height='6.5' />
                </g>
                <g>
                    <rect className='cls-1' x='278.8' y='19.2' width='6.5' height='24.8' />
                    <rect className='cls-1' x='278.8' y='9.6' width='6.5' height='6.5' />
                </g>
            </g>
            <g>
                <path className='cls-1' d='m200.3,120.5l-15.2-42.4h12.5l8.9,28,9-28h11.8l-15.2,42.4h-11.7Z' />
                <path className='cls-1' d='m229.4,73v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                <path className='cls-1' d='m245.9,120.5v-57.9h11.2v57.9h-11.2Z' />
                <path className='cls-1' d='m262.5,120.5v-57.9h11.2v57.9h-11.2Z' />
                <path
                    className='cls-1'
                    d='m289.2,102.2c.3,6.4,4,10.5,9.5,10.5s6.8-1.8,7.6-4.7h11.5c-2.6,8.7-9.6,13.6-18.6,13.6-14.1,0-21.2-7.7-21.2-23.1s7.5-21.5,20.5-21.5,19.9,8.4,19.9,25.2h-29.2Zm17.6-7.1c-.2-6.3-4.3-9.3-8.7-9.3s-8.2,3.4-8.6,9.3h17.3Z'
                />
                <path
                    className='cls-1'
                    d='m352.7,102.2c.3,6.4,4,10.5,9.5,10.5s6.8-1.8,7.6-4.7h11.5c-2.6,8.7-9.6,13.6-18.6,13.6-14.1,0-21.2-7.7-21.2-23.1s7.5-21.5,20.5-21.5,19.9,8.4,19.9,25.2h-29.2Zm17.6-7.1c-.2-6.3-4.3-9.3-8.7-9.3s-8.2,3.4-8.6,9.3h17.3Z'
                />
                <path
                    className='cls-1'
                    d='m413,120.5v-25.7c0-6.5-1.9-8.4-7.2-8.4s-8.7,3.3-8.7,9.7v24.3h-11.2v-42.4h10.7v6.2c2.6-4.7,6.9-7.3,13.7-7.3s14,4.9,14,14.1v29.4h-11.3Z'
                />
                <path className='cls-1' d='m462.6,120.5l-15.2-42.4h12.5l8.9,28,9-28h11.8l-15.2,42.4h-11.7Z' />
                <path className='cls-1' d='m491.7,73v-10.4h11.2v10.4h-11.2Zm0,47.4v-42.4h11.2v42.4h-11.2Z' />
                <path className='cls-1' d='m508.3,120.5v-57.9h11.2v57.9h-11.2Z' />
                <path className='cls-1' d='m524.8,120.5v-57.9h11.2v57.9h-11.2Z' />
                <path
                    className='cls-1'
                    d='m551.5,102.2c.3,6.4,4,10.5,9.5,10.5s6.8-1.8,7.6-4.7h11.5c-2.6,8.7-9.6,13.6-18.6,13.6-14.1,0-21.2-7.7-21.2-23.1s7.5-21.5,20.5-21.5,19.9,8.4,19.9,25.2h-29.2Zm17.6-7.1c-.2-6.3-4.3-9.3-8.7-9.3s-8.2,3.4-8.6,9.3h17.3Z'
                />
            </g>
        </svg>
    );
};

export default LogoIntercityFr;
