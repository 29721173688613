import { FC } from 'react';
import { ILogoProps } from 'shared/ui/logo-intercity';

const LogoIntercityEs: FC<ILogoProps> = ({ light }) => {
    if (light) {
        return (
            <svg
                preserveAspectRatio='xMinYMid meet'
                viewBox='0 0 210 40'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_3282_93805)'>
                    <path
                        d='M0.00288315 19.9427C0.00288315 14.4042 0.00288315 11.6364 0.775567 9.40772C2.17966 5.35978 5.36266 2.17678 9.41348 0.772684C11.6422 0 14.41 0 19.9456 0C25.4813 0 28.252 0 30.4807 0.772684C34.5315 2.17678 37.7145 5.35978 39.1186 9.4106C39.8913 11.6393 39.8913 14.4071 39.8913 19.9456C39.8913 25.4842 39.8913 28.252 39.1186 30.4807C37.7145 34.5315 34.5315 37.7145 30.4807 39.1186C28.252 39.8913 25.4842 39.8913 19.9456 39.8913C14.4071 39.8913 11.6393 39.8913 9.4106 39.1186C5.35978 37.7145 2.17678 34.5315 0.772684 30.4807C0 28.252 0 25.4842 0 19.9456'
                        fill='#A7E92F'
                    />
                    <path
                        d='M14.2317 17.1808H11.0458C10.6286 17.1808 10.2904 17.519 10.2904 17.9362V29.7398C10.2904 30.157 10.6286 30.4952 11.0458 30.4952H14.2317C14.6489 30.4952 14.9871 30.157 14.9871 29.7398V17.9362C14.9871 17.519 14.6489 17.1808 14.2317 17.1808Z'
                        fill='black'
                    />
                    <path
                        d='M14.2317 9.37903H11.0458C10.6286 9.37903 10.2904 9.71723 10.2904 10.1344V13.1934C10.2904 13.6106 10.6286 13.9488 11.0458 13.9488H14.2317C14.6489 13.9488 14.9871 13.6106 14.9871 13.1934V10.1344C14.9871 9.71723 14.6489 9.37903 14.2317 9.37903Z'
                        fill='black'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M21.8836 25.9341H19.3435C18.8909 25.9341 18.5881 26.2368 18.5881 26.6895V29.7369C18.5881 30.1896 18.8909 30.4895 19.3435 30.4923L21.8836 30.5096C36.3627 30.5096 36.3627 9.37903 21.8836 9.37903L19.3435 9.39633C18.8909 9.39921 18.5881 9.69906 18.5881 10.1517V13.1992C18.5881 13.6519 18.8909 13.9546 19.3435 13.9546H21.8836C30.0746 13.9546 30.0746 25.9341 21.8836 25.9341Z'
                        fill='black'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M94.3689 5.33687C96.8109 5.33687 98.4485 7.25416 97.9872 9.6097H92.4631C92.5842 10.6073 93.4117 11.2127 94.3371 11.2098C95.1819 11.2098 95.8796 10.8264 96.2544 10.0508L97.8229 10.8523C97.1943 12.0834 95.871 12.8936 94.3689 12.8936C92.1114 12.8821 90.5429 11.2012 90.5429 9.11379C90.5429 7.02639 92.1258 5.3484 94.3689 5.33398M96.2112 8.34111C96.0296 7.57708 95.2021 7.01486 94.3631 7.01486C93.5241 7.01486 92.7226 7.55113 92.515 8.34111H96.2112Z'
                        fill='black'
                    />
                    <path
                        d='M58.9782 6.27953L58.9234 5.54144H57.1993V12.6888H59.056V9.01276C59.056 6.82156 62.0833 6.65434 62.0833 8.53127V12.6888H63.9401V8.36405C63.9401 5.16087 60.4082 4.76876 58.9724 6.27953'
                        fill='black'
                    />
                    <path
                        d='M68.7088 10.9215H67.2788V5.35985H68.7088C70.3464 5.35985 71.5026 6.50446 71.5026 8.14209C71.5026 9.77972 70.3464 10.9243 68.7088 10.9243V10.9215ZM68.7088 3.59248H65.422V12.6888H68.7088C71.3584 12.6888 73.3593 10.7398 73.3593 8.13921C73.3593 5.53861 71.3584 3.5896 68.7088 3.5896V3.59248Z'
                        fill='black'
                    />
                    <path
                        d='M76.3982 6.31713L76.3434 5.54156H74.6192V12.6889H76.476V9.58374C76.476 7.83078 77.8109 7.0754 79.2842 7.40119V5.49255C78.3875 5.26766 77.067 5.40605 76.3953 6.32001'
                        fill='black'
                    />
                    <path
                        d='M88.5939 5.5415L86.838 10.6735L85.0937 5.5415H83.1188L85.7309 12.6888H87.9538L90.5688 5.5415H88.5939Z'
                        fill='black'
                    />
                    <path d='M55.7635 5.5415H53.9038V12.6888H55.7635V5.5415Z' fill='black' />
                    <path d='M55.7635 2.77368H53.9038V4.63331H55.7635V2.77368Z' fill='black' />
                    <path d='M82.1558 5.54163H80.2991V12.689H82.1558V5.54163Z' fill='black' />
                    <path d='M82.1587 2.7738H80.2991V4.63344H82.1587V2.7738Z' fill='black' />
                    <path
                        d='M64.88 30.2789C64.534 33.1044 62.2275 35.0159 59.2752 35.0159C55.9538 35.0159 53.6703 32.8103 53.6703 28.4452C53.6703 24.0802 55.9509 22.1715 59.3732 22.1715C62.5475 22.1715 64.7041 23.9821 64.9031 26.8566H61.6538C61.4808 25.5909 60.5639 24.8471 59.3472 24.8471C57.9835 24.8471 56.8677 25.7149 56.8677 28.3674C56.8677 31.0199 57.9835 32.3087 59.2492 32.3087C60.5149 32.3087 61.5067 31.5908 61.6307 30.276H64.88V30.2789Z'
                        fill='black'
                    />
                    <path
                        d='M65.9727 21.0299V18.0286H69.1961V21.0299H65.9727ZM65.9727 34.6932V22.4946H69.1961V34.6932H65.9727Z'
                        fill='black'
                    />
                    <path
                        d='M78.3184 34.6903V32.9806C77.4765 34.3703 76.2108 35.0132 74.5241 35.0132C72.0936 35.0132 70.3839 33.2026 70.3839 30.5242V22.4917H73.6073V30.0283C73.6073 31.565 74.3021 32.3088 75.6659 32.3088C77.3035 32.3088 78.1713 31.144 78.1713 29.5324V22.4917H81.3687V34.6903H78.3184Z'
                        fill='black'
                    />
                    <path
                        d='M91.2378 34.6902L91.2118 33.2025C90.442 34.4423 89.3032 35.0131 87.8155 35.0131C84.742 35.0131 82.5335 32.5826 82.5335 28.4684C82.5335 24.7751 84.3932 22.1716 87.5416 22.1716C89.0552 22.1716 90.2431 22.7915 91.1628 24.0802V18.0314H94.3371V34.6931H91.2378V34.6902ZM91.2868 28.8893C91.2868 26.2368 90.3699 24.9221 88.5853 24.9221C86.6997 24.9221 85.8088 26.1878 85.8088 28.6904C85.8088 30.8729 86.9505 32.3837 88.5622 32.3837C90.1739 32.3837 91.2896 30.945 91.2896 28.8864L91.2868 28.8893Z'
                        fill='black'
                    />
                    <path
                        d='M103.312 34.6902C103.214 34.3932 103.139 33.9723 103.113 33.6003C102.344 34.4422 101.055 35.0131 99.4202 35.0131C96.6437 35.0131 95.3549 33.6494 95.3549 31.6658C95.3549 28.1454 97.7105 27.7476 100.96 27.3007C102.571 27.0787 103.018 26.7557 103.018 25.911C103.018 25.1181 102.225 24.6453 100.96 24.6453C99.498 24.6453 98.9012 25.3632 98.7542 26.4559H95.7528C95.8018 23.9274 97.1915 22.1658 101.11 22.1658C105.028 22.1658 106.317 23.9014 106.317 26.9749V34.6873H103.315L103.312 34.6902ZM103.09 28.7134C102.768 29.0363 102.197 29.2093 101.009 29.4313C99.1751 29.7773 98.5783 30.2991 98.5783 31.34C98.5783 32.2568 99.1232 32.7037 100.141 32.7037C101.802 32.7037 103.041 31.4899 103.067 30.0512L103.093 28.7134H103.09Z'
                        fill='black'
                    />
                    <path
                        d='M116.18 34.6902L116.154 33.2025C115.384 34.4423 114.245 35.0131 112.758 35.0131C109.684 35.0131 107.476 32.5826 107.476 28.4684C107.476 24.7751 109.335 22.1716 112.484 22.1716C113.997 22.1716 115.185 22.7915 116.105 24.0802V18.0314H119.279V34.6931H116.18V34.6902ZM116.229 28.8893C116.229 26.2368 115.312 24.9221 113.527 24.9221C111.642 24.9221 110.751 26.1878 110.751 28.6904C110.751 30.8729 111.893 32.3837 113.504 32.3837C115.116 32.3837 116.232 30.945 116.232 28.8864L116.229 28.8893Z'
                        fill='black'
                    />
                    <path
                        d='M134.306 34.6902C134.208 34.3932 134.133 33.9723 134.107 33.6003C133.338 34.4422 132.049 35.0131 130.414 35.0131C127.638 35.0131 126.349 33.6494 126.349 31.6658C126.349 28.1454 128.704 27.7476 131.954 27.3007C133.565 27.0787 134.012 26.7557 134.012 25.911C134.012 25.1181 133.219 24.6453 131.954 24.6453C130.492 24.6453 129.895 25.3632 129.748 26.4559H126.747C126.796 23.9274 128.185 22.1658 132.104 22.1658C136.022 22.1658 137.311 23.9014 137.311 26.9749V34.6873H134.309L134.306 34.6902ZM134.084 28.7134C133.761 29.0363 133.19 29.2093 132.003 29.4313C130.169 29.7773 129.572 30.2991 129.572 31.34C129.572 32.2568 130.117 32.7037 131.135 32.7037C132.796 32.7037 134.035 31.4899 134.061 30.0512L134.087 28.7134H134.084Z'
                        fill='black'
                    />
                    <path
                        d='M155.679 30.2789C155.333 33.1044 153.027 35.0159 150.074 35.0159C146.753 35.0159 144.469 32.8103 144.469 28.4452C144.469 24.0802 146.75 22.1715 150.172 22.1715C153.347 22.1715 155.503 23.9821 155.702 26.8566H152.453C152.28 25.5909 151.363 24.8471 150.146 24.8471C148.783 24.8471 147.667 25.7149 147.667 28.3674C147.667 31.0199 148.783 32.3087 150.048 32.3087C151.314 32.3087 152.306 31.5908 152.43 30.276H155.679V30.2789Z'
                        fill='black'
                    />
                    <path
                        d='M156.746 21.0299V18.0286H159.969V21.0299H156.746ZM156.746 34.6932V22.4946H159.969V34.6932H156.746Z'
                        fill='black'
                    />
                    <path
                        d='M169.091 34.6903V32.9806C168.25 34.3703 166.984 35.0132 165.297 35.0132C162.867 35.0132 161.157 33.2026 161.157 30.5242V22.4917H164.38V30.0283C164.38 31.565 165.075 32.3088 166.439 32.3088C168.077 32.3088 168.944 31.144 168.944 29.5324V22.4917H172.142V34.6903H169.091Z'
                        fill='black'
                    />
                    <path
                        d='M181.936 34.6902L181.91 33.2025C181.14 34.4423 180.001 35.0131 178.514 35.0131C175.44 35.0131 173.232 32.5826 173.232 28.4684C173.232 24.7751 175.091 22.1716 178.24 22.1716C179.753 22.1716 180.941 22.7915 181.861 24.0802V18.0314H185.035V34.6931H181.936V34.6902ZM181.985 28.8893C181.985 26.2368 181.068 24.9221 179.283 24.9221C177.398 24.9221 176.507 26.1878 176.507 28.6904C176.507 30.8729 177.649 32.3837 179.26 32.3837C180.872 32.3837 181.988 30.945 181.988 28.8864L181.985 28.8893Z'
                        fill='black'
                    />
                    <path
                        d='M194.06 34.6902C193.961 34.3932 193.887 33.9723 193.861 33.6003C193.091 34.4422 191.802 35.0131 190.167 35.0131C187.391 35.0131 186.102 33.6494 186.102 31.6658C186.102 28.1454 188.458 27.7476 191.707 27.3007C193.319 27.0787 193.765 26.7557 193.765 25.911C193.765 25.1181 192.973 24.6453 191.707 24.6453C190.245 24.6453 189.648 25.3632 189.501 26.4559H186.5C186.549 23.9274 187.939 22.1658 191.857 22.1658C195.775 22.1658 197.064 23.9014 197.064 26.9749V34.6873H194.062L194.06 34.6902ZM193.838 28.7134C193.515 29.0363 192.944 29.2093 191.756 29.4313C189.922 29.7773 189.325 30.2991 189.325 31.34C189.325 32.2568 189.87 32.7037 190.888 32.7037C192.549 32.7037 193.788 31.4899 193.814 30.0512L193.84 28.7134H193.838Z'
                        fill='black'
                    />
                    <path
                        d='M206.904 34.6902L206.878 33.2025C206.108 34.4423 204.969 35.0131 203.482 35.0131C200.408 35.0131 198.2 32.5826 198.2 28.4684C198.2 24.7751 200.059 22.1716 203.208 22.1716C204.721 22.1716 205.909 22.7915 206.829 24.0802V18.0314H210.003V34.6931H206.904V34.6902ZM206.953 28.8893C206.953 26.2368 206.036 24.9221 204.251 24.9221C202.366 24.9221 201.475 26.1878 201.475 28.6904C201.475 30.8729 202.617 32.3837 204.228 32.3837C205.84 32.3837 206.956 30.945 206.956 28.8864L206.953 28.8893Z'
                        fill='black'
                    />
                </g>
                <defs>
                    <clipPath id='clip0_3282_93805'>
                        <rect width='210' height='40' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        );
    }

    return (
        <svg preserveAspectRatio='xMinYMid meet' viewBox='0 0 210 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_3282_93806)'>
                <path
                    d='M0.00288315 19.9428C0.00288315 14.4042 0.00288315 11.6364 0.775568 9.40772C2.17966 5.35978 5.36266 2.17678 9.41349 0.772684C11.6422 0 14.41 0 19.9456 0C25.4813 0 28.252 0 30.4807 0.772684C34.5315 2.17678 37.7145 5.35978 39.1186 9.4106C39.8913 11.6393 39.8913 14.4071 39.8913 19.9456C39.8913 25.4842 39.8913 28.252 39.1186 30.4807C37.7145 34.5315 34.5315 37.7145 30.4807 39.1186C28.252 39.8913 25.4842 39.8913 19.9456 39.8913C14.4071 39.8913 11.6393 39.8913 9.4106 39.1186C5.35978 37.7145 2.17678 34.5315 0.772684 30.4807C0 28.252 0 25.4842 0 19.9456'
                    fill='#A7E92F'
                />
                <path
                    d='M14.2314 17.1807H11.0455C10.6283 17.1807 10.2901 17.5189 10.2901 17.936V29.7397C10.2901 30.1569 10.6283 30.495 11.0455 30.495H14.2314C14.6486 30.495 14.9868 30.1569 14.9868 29.7397V17.936C14.9868 17.5189 14.6486 17.1807 14.2314 17.1807Z'
                    fill='black'
                />
                <path
                    d='M14.2314 9.37891H11.0455C10.6283 9.37891 10.2901 9.7171 10.2901 10.1343V13.1933C10.2901 13.6105 10.6283 13.9487 11.0455 13.9487H14.2314C14.6486 13.9487 14.9868 13.6105 14.9868 13.1933V10.1343C14.9868 9.7171 14.6486 9.37891 14.2314 9.37891Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.8833 25.934H19.3432C18.8906 25.934 18.5878 26.2367 18.5878 26.6893V29.7368C18.5878 30.1895 18.8906 30.4893 19.3432 30.4922L21.8833 30.5095C36.3625 30.5095 36.3625 9.37891 21.8833 9.37891L19.3432 9.39621C18.8906 9.39909 18.5878 9.69894 18.5878 10.1516V13.1991C18.5878 13.6517 18.8906 13.9545 19.3432 13.9545H21.8833C30.0743 13.9545 30.0743 25.934 21.8833 25.934Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M94.3685 5.33662C96.8106 5.33662 98.4482 7.25392 97.9869 9.60945H92.4628C92.5839 10.607 93.4113 11.2125 94.3368 11.2096C95.1816 11.2096 95.8793 10.8261 96.2541 10.0506L97.8226 10.8521C97.194 12.0832 95.8707 12.8934 94.3685 12.8934C92.111 12.8818 90.5426 11.201 90.5426 9.11355C90.5426 7.02615 92.1254 5.34816 94.3685 5.33374M96.2109 8.34087C96.0292 7.57683 95.2018 7.01462 94.3628 7.01462C93.5238 7.01462 92.7223 7.55088 92.5147 8.34087H96.2109Z'
                    fill='white'
                />
                <path
                    d='M58.9779 6.27953L58.9231 5.54144H57.199V12.6888H59.0558V9.01276C59.0558 6.82156 62.0831 6.65434 62.0831 8.53127V12.6888H63.9398V8.36405C63.9398 5.16087 60.4079 4.76876 58.9721 6.27953'
                    fill='white'
                />
                <path
                    d='M68.7085 10.9213H67.2785V5.35973H68.7085C70.3462 5.35973 71.5023 6.50434 71.5023 8.14197C71.5023 9.7796 70.3462 10.9242 68.7085 10.9242V10.9213ZM68.7085 3.59236H65.4218V12.6887H68.7085C71.3582 12.6887 73.3591 10.7397 73.3591 8.13909C73.3591 5.53849 71.3582 3.58948 68.7085 3.58948V3.59236Z'
                    fill='white'
                />
                <path
                    d='M76.3978 6.31688L76.343 5.54132H74.6189V12.6886H76.4757V9.58349C76.4757 7.83054 77.8106 7.07515 79.2839 7.40095V5.4923C78.3872 5.26742 77.0667 5.40581 76.3949 6.31977'
                    fill='white'
                />
                <path
                    d='M88.5936 5.54138L86.8377 10.6734L85.0934 5.54138H83.1185L85.7306 12.6887H87.9535L90.5685 5.54138H88.5936Z'
                    fill='white'
                />
                <path d='M55.7632 5.54138H53.9035V12.6887H55.7632V5.54138Z' fill='white' />
                <path d='M55.7631 2.77356H53.9035V4.63319H55.7631V2.77356Z' fill='white' />
                <path d='M82.1555 5.54138H80.2987V12.6887H82.1555V5.54138Z' fill='white' />
                <path d='M82.1583 2.77356H80.2987V4.63319H82.1583V2.77356Z' fill='white' />
                <path
                    d='M64.8797 30.2788C64.5338 33.1043 62.2272 35.0158 59.2749 35.0158C55.9535 35.0158 53.67 32.8102 53.67 28.4451C53.67 24.08 55.9506 22.1714 59.3729 22.1714C62.5473 22.1714 64.7039 23.982 64.9028 26.8565H61.6535C61.4805 25.5908 60.5637 24.8469 59.347 24.8469C57.9832 24.8469 56.8675 25.7148 56.8675 28.3673C56.8675 31.0198 57.9832 32.3085 59.2489 32.3085C60.5146 32.3085 61.5064 31.5906 61.6304 30.2759H64.8797V30.2788Z'
                    fill='white'
                />
                <path
                    d='M65.9724 21.0297V18.0283H69.1958V21.0297H65.9724ZM65.9724 34.6929V22.4943H69.1958V34.6929H65.9724Z'
                    fill='white'
                />
                <path
                    d='M78.318 34.6899V32.9802C77.4762 34.3699 76.2105 35.0129 74.5238 35.0129C72.0933 35.0129 70.3836 33.2022 70.3836 30.5238V22.4913H73.607V30.0279C73.607 31.5646 74.3018 32.3085 75.6655 32.3085C77.3032 32.3085 78.171 31.1437 78.171 29.532V22.4913H81.3684V34.6899H78.318Z'
                    fill='white'
                />
                <path
                    d='M91.2374 34.69L91.2115 33.2023C90.4417 34.442 89.3028 35.0129 87.8151 35.0129C84.7417 35.0129 82.5332 32.5824 82.5332 28.4681C82.5332 24.7748 84.3928 22.1713 87.5412 22.1713C89.0549 22.1713 90.2427 22.7912 91.1625 24.08V18.0311H94.3368V34.6929H91.2374V34.69ZM91.2864 28.8891C91.2864 26.2366 90.3696 24.9219 88.5849 24.9219C86.6994 24.9219 85.8085 26.1876 85.8085 28.6901C85.8085 30.8727 86.9502 32.3834 88.5619 32.3834C90.1736 32.3834 91.2893 30.9448 91.2893 28.8862L91.2864 28.8891Z'
                    fill='white'
                />
                <path
                    d='M103.312 34.6899C103.214 34.393 103.139 33.972 103.113 33.6001C102.343 34.442 101.055 35.0128 99.4198 35.0128C96.6433 35.0128 95.3545 33.6491 95.3545 31.6655C95.3545 28.1452 97.7101 27.7473 100.959 27.3004C102.571 27.0784 103.018 26.7555 103.018 25.9107C103.018 25.1179 102.225 24.645 100.959 24.645C99.4976 24.645 98.9008 25.3629 98.7538 26.4557H95.7524C95.8014 23.9271 97.1911 22.1655 101.109 22.1655C105.028 22.1655 106.316 23.9012 106.316 26.9746V34.687H103.315L103.312 34.6899ZM103.09 28.7132C102.767 29.0361 102.196 29.2091 101.008 29.4311C99.1747 29.777 98.5779 30.2989 98.5779 31.3397C98.5779 32.2566 99.1228 32.7034 100.141 32.7034C101.801 32.7034 103.041 31.4896 103.067 30.0509L103.093 28.7132H103.09Z'
                    fill='white'
                />
                <path
                    d='M116.18 34.69L116.154 33.2023C115.384 34.442 114.245 35.0129 112.757 35.0129C109.684 35.0129 107.475 32.5824 107.475 28.4681C107.475 24.7748 109.335 22.1713 112.483 22.1713C113.997 22.1713 115.185 22.7912 116.105 24.08V18.0311H119.279V34.6929H116.18V34.69ZM116.229 28.8891C116.229 26.2366 115.312 24.9219 113.527 24.9219C111.641 24.9219 110.751 26.1876 110.751 28.6901C110.751 30.8727 111.892 32.3834 113.504 32.3834C115.116 32.3834 116.231 30.9448 116.231 28.8862L116.229 28.8891Z'
                    fill='white'
                />
                <path
                    d='M134.306 34.6899C134.208 34.393 134.133 33.972 134.107 33.6001C133.337 34.442 132.048 35.0128 130.414 35.0128C127.637 35.0128 126.348 33.6491 126.348 31.6655C126.348 28.1452 128.704 27.7473 131.953 27.3004C133.565 27.0784 134.012 26.7555 134.012 25.9107C134.012 25.1179 133.219 24.645 131.953 24.645C130.492 24.645 129.895 25.3629 129.748 26.4557H126.746C126.795 23.9271 128.185 22.1655 132.103 22.1655C136.021 22.1655 137.31 23.9012 137.31 26.9746V34.687H134.309L134.306 34.6899ZM134.084 28.7132C133.761 29.0361 133.19 29.2091 132.002 29.4311C130.169 29.777 129.572 30.2989 129.572 31.3397C129.572 32.2566 130.117 32.7034 131.135 32.7034C132.795 32.7034 134.035 31.4896 134.061 30.0509L134.087 28.7132H134.084Z'
                    fill='white'
                />
                <path
                    d='M155.679 30.2788C155.333 33.1043 153.026 35.0158 150.074 35.0158C146.753 35.0158 144.469 32.8102 144.469 28.4451C144.469 24.08 146.75 22.1714 150.172 22.1714C153.346 22.1714 155.503 23.982 155.702 26.8565H152.452C152.28 25.5908 151.363 24.8469 150.146 24.8469C148.782 24.8469 147.666 25.7148 147.666 28.3673C147.666 31.0198 148.782 32.3085 150.048 32.3085C151.314 32.3085 152.305 31.5906 152.429 30.2759H155.679V30.2788Z'
                    fill='white'
                />
                <path
                    d='M156.746 21.0297V18.0283H159.969V21.0297H156.746ZM156.746 34.6929V22.4943H159.969V34.6929H156.746Z'
                    fill='white'
                />
                <path
                    d='M169.091 34.6899V32.9802C168.249 34.3699 166.984 35.0129 165.297 35.0129C162.866 35.0129 161.157 33.2022 161.157 30.5238V22.4913H164.38V30.0279C164.38 31.5646 165.075 32.3085 166.439 32.3085C168.076 32.3085 168.944 31.1437 168.944 29.532V22.4913H172.141V34.6899H169.091Z'
                    fill='white'
                />
                <path
                    d='M181.936 34.69L181.91 33.2023C181.14 34.442 180.001 35.0129 178.513 35.0129C175.44 35.0129 173.231 32.5824 173.231 28.4681C173.231 24.7748 175.091 22.1713 178.239 22.1713C179.753 22.1713 180.941 22.7912 181.861 24.08V18.0311H185.035V34.6929H181.936V34.69ZM181.985 28.8891C181.985 26.2366 181.068 24.9219 179.283 24.9219C177.398 24.9219 176.507 26.1876 176.507 28.6901C176.507 30.8727 177.648 32.3834 179.26 32.3834C180.872 32.3834 181.988 30.9448 181.988 28.8862L181.985 28.8891Z'
                    fill='white'
                />
                <path
                    d='M194.059 34.6899C193.961 34.393 193.886 33.972 193.86 33.6001C193.091 34.442 191.802 35.0128 190.167 35.0128C187.391 35.0128 186.102 33.6491 186.102 31.6655C186.102 28.1452 188.457 27.7473 191.707 27.3004C193.318 27.0784 193.765 26.7555 193.765 25.9107C193.765 25.1179 192.972 24.645 191.707 24.645C190.245 24.645 189.648 25.3629 189.501 26.4557H186.5C186.549 23.9271 187.938 22.1655 191.857 22.1655C195.775 22.1655 197.063 23.9012 197.063 26.9746V34.687H194.062L194.059 34.6899ZM193.837 28.7132C193.514 29.0361 192.943 29.2091 191.756 29.4311C189.922 29.777 189.325 30.2989 189.325 31.3397C189.325 32.2566 189.87 32.7034 190.888 32.7034C192.548 32.7034 193.788 31.4896 193.814 30.0509L193.84 28.7132H193.837Z'
                    fill='white'
                />
                <path
                    d='M206.904 34.69L206.878 33.2023C206.108 34.442 204.969 35.0129 203.481 35.0129C200.408 35.0129 198.199 32.5824 198.199 28.4681C198.199 24.7748 200.059 22.1713 203.207 22.1713C204.721 22.1713 205.909 22.7912 206.829 24.08V18.0311H210.003V34.6929H206.904V34.69ZM206.953 28.8891C206.953 26.2366 206.036 24.9219 204.251 24.9219C202.366 24.9219 201.475 26.1876 201.475 28.6901C201.475 30.8727 202.616 32.3834 204.228 32.3834C205.84 32.3834 206.956 30.9448 206.956 28.8862L206.953 28.8891Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_3282_93806'>
                    <rect width='210' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LogoIntercityEs;
